import { createTheme, CssBaseline, responsiveFontSizes, ThemeProvider } from '@mui/material';
import PropTypes from 'prop-types';
import { enUS, nlNL } from '@mui/x-data-grid-premium/locales';
import { enUS as coreEnUS, nlNL as coreNlNL } from '@mui/material/locale';
import { useTranslation } from 'react-i18next';

function Theme({ children }) {
    const { i18n } = useTranslation();

    let locale;

    switch (i18n.language) {
        case 'nl':
            locale = [nlNL, coreNlNL];
            break;
        case 'en':
            locale = [enUS, coreEnUS];
            break;
        default:
            locale = [enUS, coreEnUS];
            break;
    }

    const palette = {
        mode: 'light',
        primary: {
            main: '#f2690d',
            light: '#ff9a44',
            dark: '#b83900',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#ffab91',
            light: '#ffddc1',
            dark: '#c97b63',
            contrastText: '#000000'
        },
        info: {
            main: 'rgba(2,136,209,0.75)'
        },
        success: {
            main: 'rgba(46,125,50,0.75)'
        },
        warning: {
            main: 'rgba(237,108,2,0.75)'
        },
        error: {
            main: 'rgba(211,47,47,0.75)'
        },
        // Colours shamelessly stolen from Swagger
        requestMethod: {
            GET: {
                main: '#61AFFE',
                text: '#FFFFFF'
            },
            HEAD: {
                main: '#9027FA',
                text: '#FFFFFF'
            },
            POST: {
                main: '#49CC90',
                text: '#FFFFFF'
            },
            PUT: {
                main: '#FCA130',
                text: '#FFFFFF'
            },
            DELETE: {
                main: '#F93E3E',
                text: '#FFFFFF'
            },
            OPTIONS: {
                main: '#0D5CA7',
                text: '#FFFFFF'
            },
            PATCH: {
                main: '#57E3C3',
                text: '#000000'
            }
        }
    };

    let theme = createTheme(
        {
            palette: palette,
            theme: {
                accent: {
                    light: '#ffab91',
                    dark: '#f2690d'
                }
            },
            components: {
                MuiListItemButton: {
                    variants: [
                        {
                            props: { variant: 'navbar' },
                            style: {
                                borderStartEndRadius: 360,
                                borderEndEndRadius: 360,
                                marginRight: '.25rem'
                            }
                        }
                    ]
                },
                MuiTypography: {
                    defaultProps: {
                        variantMapping: {
                            monospace: 'p'
                        }
                    }
                }
            },
            typography: {
                muted: {
                    color: 'grey',
                    fontWeight: 400,
                    fontSize: '1rem',
                    lineHeight: 1.75,
                    letterSpacing: '0.00938em'
                },
                monospace: {
                    fontFamily: '"Roboto mono",mono'
                }
            }
        },
        ...locale
    );

    theme = responsiveFontSizes(theme);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
}

Theme.propTypes = {
    children: PropTypes.array
};

export default Theme;
