import { toast } from 'react-toastify';

export function getCurrentUser() {
    try {
        const user = localStorage.getItem('user');

        return JSON.parse(user);
    } catch (e) {
        toast.error(e);
        localStorage.clear();
    }
}
