import { useEffect, useState } from 'react';
import fetcher from '../../fetcher';
import { Card, CardContent, CardHeader } from '@mui/material';
import { DateTime } from 'luxon';
import { Pie } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import stringToColour from '../../utils/stringToColour';
import useDateRange from '../../hooks/useDateRange';

const SourcePie = () => {
    const { t, i18n } = useTranslation(['translation', 'statistics', 'orders']);
    const dateRange = useDateRange();

    ChartJS.register(ArcElement, Tooltip, Legend);

    const [sources, setSources] = useState([]);
    const [sourcesDataSet, setSourcesDataSet] = useState([]);
    const [labels, setLabels] = useState([]);
    const [colours, setColours] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        populateSources();
    }, [dateRange.dateRange]);

    useEffect(() => {
        populateStatistics();
    }, [sources]);

    useEffect(() => {
        return () => {
            dateRange.setRefreshDelay(0);

            if (dateRange.refreshInterval !== null) {
                clearInterval(dateRange.refreshInterval);
                dateRange.setRefreshInterval(null);
            }
        };
    }, []);

    useEffect(() => {
        if (dateRange.refreshInterval !== null) {
            clearInterval(dateRange.refreshInterval);
            dateRange.setRefreshInterval(null);
        }

        if (dateRange.refreshDelay > 0) {
            dateRange.setRefreshInterval(
                setInterval(() => {
                    populateSources();
                }, dateRange.refreshDelay)
            );
        }
    }, [dateRange.refreshDelay]);

    const populateSources = () => {
        setLoading(true);
        const newDateRange = dateRange.getDateRange();

        const startDate = newDateRange.start?.toISO({
            includeOffset: false
        });

        const endDate = newDateRange.end?.toISO({ includeOffset: false });

        fetcher(`v1/admin/statistic/source?startDate=${startDate}&endDate=${endDate}`)
            .then(async (result) => {
                result = await result.json();

                if (result) {
                    setSources(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const populateStatistics = () => {
        const newLabels = [];
        const newData = [];
        const newColours = [];

        sources.forEach((source) => {
            const name = t(source.name.split('@')[1] ?? 'unknown', { ns: 'orders' });

            const index = newLabels.findIndex((value) => value === name);

            if (index === -1) {
                newLabels.push(name);
                newData.push(parseInt(source.volume));
                newColours.push(stringToColour(name));
            } else {
                newData[index] += parseInt(source.volume);
            }
        });

        setColours(newColours);
        setLabels(newLabels);
        setSourcesDataSet(newData);
    };

    return (
        <Card>
            <CardHeader
                title={t('distributionBySource', { ns: 'statistics' })}
                subheader={
                    dateRange.getDateRange().start.toLocaleString(DateTime.DATETIME_SHORT) +
                    '–' +
                    dateRange.getDateRange().end.toLocaleString(DateTime.DATETIME_SHORT)
                }
            />
            <CardContent>
                <Pie
                    options={{
                        locale: i18n.language
                    }}
                    data={{
                        labels: labels,
                        datasets: [
                            {
                                data: sourcesDataSet,
                                backgroundColor: colours
                            }
                        ]
                    }}
                />
            </CardContent>
        </Card>
    );
};

export default SourcePie;
