import {
    Autocomplete,
    Box,
    Button,
    Chip,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    Switch,
    TextField,
    Tooltip
} from '@mui/material';
import { UploadFile } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { getPaymentProfileIcon, getPaymentProfileName } from './PaymentProfileMap';
import _, { isString } from 'lodash';
import DecimalInput from './DecimalInput';
import PercentInput from './PercentInput';
import fetcher from '../../fetcher';

//region General
export const roundTo = (n, digits) => {
    let negative = false;

    if (digits === undefined) {
        digits = 0;
    }
    if (n < 0) {
        negative = true;
        n = n * -1;
    }

    const multiplicator = Math.pow(10, digits);

    n = parseFloat((n * multiplicator).toFixed(11));
    n = (Math.round(n) / multiplicator).toFixed(digits);

    if (negative) {
        n = (n * -1).toFixed(digits);
    }
    return n;
};

export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};
//endregion

//region Access log
export const getRequestMethodChip = (requestMethod, theme) => {
    return (
        <Chip
            label={requestMethod}
            sx={{
                backgroundColor: theme.palette.requestMethod[requestMethod].main,
                color: theme.palette.requestMethod[requestMethod].text
            }}
        />
    );
};
//endregion

//region Transactions
export const getStatusColor = (status) => {
    status = getStatusType(status);

    switch (status?.toLowerCase()) {
        case 'pending':
            return 'info';
        case 'cancel':
            return 'error';
        case 'success':
            return 'success';
        default:
            return 'warning';
    }
};

export const getStatusType = (status) => {
    if (_.isNil(status)) {
        return status;
    }

    switch (status.toLowerCase()) {
        case '(-4) user_cancel':
        case '(-5) timeout':
        case '(-9) use_chip':
        case '(-12) transaction_declined_by_server':
        case '(-14) connection_error':
        case '(-62) reseller_order_declined':
        case '(-1000) wrong_parameters':
        case '(-1020) invalid_input_cannot_apply_installments_with_tip':
        case '(-10055) wrong_pin_tap_again':
        case 'cancel':
        case 'cancelled':
        case 'connection_failure':
        case 'device_cancellation':
        case 'external_equipment_cancellation':
        case 'host_cancellation':
        case 'error':
        case 'no_purse_in_reader_and_timeout_expired':
        case 'technical_problem':
        case 'timeout_on_fallback_card_reading':
        case 'unexpected_message':
        case 'unsupported_message':
        case 'user_cancellation':
            return 'cancel';
        case 'complete':
        case 'new_ppt':
        case 'paid':
            return 'success';
        case 'pending':
        default:
            return 'pending';
    }
};

export const getPaymentProfile = (paymentProfileId, type, t) => {
    let imgUrl;
    let brandName;

    if (paymentProfileId !== null && paymentProfileId !== 0) {
        imgUrl = getPaymentProfileIcon(paymentProfileId);
        brandName = getPaymentProfileName(paymentProfileId);
    } else {
        const paymentMethod = type.split('@')[0];

        imgUrl = getPaymentProfileIcon(paymentMethod);
        brandName = getPaymentProfileName(paymentMethod);
    }

    if (isString(imgUrl)) {
        return (
            <Tooltip title={t(brandName, { ns: 'orders' })}>
                <img alt={t(brandName, { ns: 'orders' })} style={{ verticalAlign: 'middle' }} src={imgUrl} width="25rem" height="25rem" />
            </Tooltip>
        );
    } else {
        return t(brandName, { ns: 'orders' });
    }
};
//endregion

//region Settings
export const RenderSettingField = (props) => {
    const {
        t,
        id,
        type,
        label,
        value,
        required,
        allowedValues = null,
        index,
        onSettingChange,
        displayHelperText = false,
        userId = null
    } = props;
    const translatedLabel = t(label, { ns: 'settings' });
    const helperText = displayHelperText ? t(label + 'Description', { ns: 'settings' }) : '';

    switch (type) {
        case 'string':
            return (
                <TextField
                    fullWidth
                    margin="dense"
                    onChange={(event) => onSettingChange(event, index)}
                    value={value}
                    required={required}
                    label={translatedLabel}
                    helperText={helperText}
                />
            );
        case 'decimal':
            return (
                <TextField
                    margin="dense"
                    required={required}
                    fullWidth
                    label={translatedLabel}
                    value={value}
                    onChange={(event) => onSettingChange(event, index)}
                    inputProps={{
                        inputMode: 'decimal',
                        pattern: '[0-9]*'
                    }}
                    helperText={helperText}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">&euro;</InputAdornment>,
                        inputComponent: DecimalInput
                    }}
                />
            );
        case 'boolean':
            return (
                <>
                    <FormControl fullWidth required={required} margin="dense">
                        <FormControlLabel
                            control={
                                <Switch
                                    required={required}
                                    onChange={(event) => onSettingChange(event, index)}
                                    checked={value === 'true'}
                                />
                            }
                            label={translatedLabel}
                        />
                    </FormControl>
                    <FormHelperText>{helperText}</FormHelperText>
                </>
            );
        case 'select':
            if (allowedValues !== null) {
                const allowedValuesParts = allowedValues.split('::');
                const values = [];

                switch (allowedValuesParts[0]) {
                    case 'array':
                        values.push(...allowedValuesParts[1].split(','));
                        break;
                    case 'table':
                        break;
                    default:
                        values.push(`Unknown source type: ${allowedValuesParts[0]}`);
                        break;
                }

                return (
                    <>
                        <FormControl fullWidth margin="dense">
                            <InputLabel id={`${label}-label`} required={required}>
                                {translatedLabel}
                            </InputLabel>
                            <Select
                                fullWidth
                                labelId={`${label}-label`}
                                onChange={(event) => onSettingChange(event, index)}
                                value={value}
                                label={translatedLabel}
                            >
                                {values.map((value) => (
                                    <MenuItem key={value} value={value}>
                                        {t(`label_options_${value}`, { ns: 'settings' })}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormHelperText>{helperText}</FormHelperText>
                    </>
                );
            }

            break;
        case 'multiple_select':
            if (allowedValues !== null) {
                const valueDeserialised = _.isString(value) ? JSON.parse(value) : value;
                /**
                 * Temporary workaround to handle new settings type.
                 * We're reworking the whole settings system at some point.
                 */
                switch (label) {
                    case 'linked_phone_numbers': {
                        return (
                            <>
                                <FormControl fullWidth margin="dense">
                                    <Autocomplete
                                        multiple
                                        clearOnBlur
                                        disableCloseOnSelect
                                        filterSelectedOptions
                                        value={valueDeserialised}
                                        options={allowedValues}
                                        getOptionLabel={(option) => {
                                            const res = allowedValues.find((e) => e.first === option.first);

                                            if (res === undefined) {
                                                return option.first;
                                            }

                                            return `${res.second} ${res.first}`;
                                        }}
                                        isOptionEqualToValue={(option, value) => option.second === value.second}
                                        onChange={(event, value) => onSettingChange(value, index)}
                                        renderInput={(params) => <TextField {...params} label={translatedLabel} />}
                                    />
                                </FormControl>
                                <FormHelperText>{helperText}</FormHelperText>
                            </>
                        );
                    }
                    case 'user_allowed_cash_registers': {
                        return (
                            <>
                                <FormControl fullWidth margin="dense">
                                    <Autocomplete
                                        multiple
                                        clearOnBlur
                                        disableCloseOnSelect
                                        filterSelectedOptions
                                        value={valueDeserialised}
                                        options={allowedValues}
                                        getOptionLabel={(option) => {
                                            const res = allowedValues.find((e) => e.first === option.first);

                                            if (res === undefined) {
                                                return option.second;
                                            }

                                            return res.second;
                                        }}
                                        isOptionEqualToValue={(option, value) => option.first === value.first}
                                        onChange={(event, value) => onSettingChange(value, index)}
                                        renderInput={(params) => <TextField {...params} label={translatedLabel} />}
                                    />
                                </FormControl>
                                <FormHelperText>{helperText}</FormHelperText>
                            </>
                        );
                    }
                }
            }

            break;
        case 'percentage':
            return (
                <TextField
                    margin="dense"
                    required={required}
                    fullWidth
                    label={translatedLabel}
                    value={value}
                    onChange={(event) => onSettingChange(event, index)}
                    inputProps={{
                        inputMode: 'decimal',
                        pattern: '[0-9]*'
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        inputComponent: PercentInput
                    }}
                    helperText={helperText}
                />
            );
        case 'phone':
            return (
                <TextField
                    margin="dense"
                    fullWidth
                    onChange={(event) => onSettingChange(event, index)}
                    required={required}
                    label={translatedLabel}
                    value={value}
                    helperText={helperText}
                />
            );
        case 'file':
            return (
                <Button variant="contained" component="label">
                    <UploadFile /> &nbsp; {translatedLabel}
                    <input type="file" hidden />
                </Button>
            );
        default:
            return `Unknown setting type: ${type}`;
    }
};

export const RenderSettings = (props) => {
    const { settings, t, onSettingChange, changed, discardSettings, saveSettings, userId = null } = props;

    return (
        <>
            <Box component="form">
                {settings.map((setting, index) => (
                    <Box key={setting.setting.id}>
                        <RenderSettingField
                            t={t}
                            id={setting.setting.id}
                            type={setting.setting.type}
                            label={setting.setting.key}
                            value={setting.value ?? ''}
                            required={setting.setting.required}
                            allowedValues={setting.setting.allowedValuesResolved}
                            index={index}
                            onSettingChange={onSettingChange}
                            displayHelperText={setting.setting.displayHelperText}
                            userId={userId}
                        />
                    </Box>
                ))}
            </Box>
            <Snackbar
                open={changed}
                message={t('unsavedChanges', { ns: 'translation' })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                key={'bottomright'}
                action={
                    <>
                        <Button color="secondary" size="small" onClick={discardSettings}>
                            Discard
                        </Button>
                        <Button color="primary" size="small" onClick={saveSettings}>
                            Save
                        </Button>
                    </>
                }
            />
        </>
    );
};

export const getValuesFromTable = async (settingId, userId) => {
    return await fetcher(`v1/setting/allowedValues?settingId=${settingId}&userId=${userId}`).then(async (result) => {
        if (result) {
            result = await result.json();
            return result;
        }
    });
};
//endregion

/**
 * Method:      getValuesFromTableV2
 * Props:
 *  settingId:  UUID
 *  key:        setting key
 *  id:         organisationId or UserId, depending on the key instant
 */
export const getValuesFromTableV2 = async (settingId, id) => {
    return await fetcher(`v1/setting/multiselect?settingId=${settingId}&id=${id}`).then(async (result) => {
        if (result) {
            result = await result.json();
            return result;
        }
    });
};

//region PropTypes
RenderSettingField.propTypes = {
    t: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    required: PropTypes.bool.isRequired,
    allowedValues: PropTypes.array,
    index: PropTypes.number.isRequired,
    onSettingChange: PropTypes.func.isRequired,
    displayHelperText: PropTypes.bool,
    userId: PropTypes.string
};

RenderSettings.propTypes = {
    settings: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    onSettingChange: PropTypes.func.isRequired,
    changed: PropTypes.bool.isRequired,
    discardSettings: PropTypes.func.isRequired,
    saveSettings: PropTypes.func.isRequired,
    userId: PropTypes.string
};
//endregion
