import { useEffect, useState } from 'react';
import fetcher from '../../fetcher';
import { List, ListItemButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function SelectEvent() {
    const navigate = useNavigate();

    const [events, setEvents] = useState([]);

    useEffect(() => {
        fetcher('/v1/finance/events', false, {
            method: 'GET'
        }).then(async (result) => {
            if (result) {
                result = await result.json();

                setEvents(result);
            }
        });
    }, []);

    return (
        <List>
            {events.map((event) => (
                <ListItemButton key={event.id} onClick={() => navigate(`/statistics/finance/${event.id}`)}>
                    {event.name}
                </ListItemButton>
            ))}
        </List>
    );
}

export default SelectEvent;
