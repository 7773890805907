import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Tooltip
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import useDateRange from '../../hooks/useDateRange';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const DateRangeForm = (props) => {
    const dateRange = useDateRange();
    const { t, i18n } = useTranslation(['translation', 'statistics']);

    const handleDateRangeChange = (event) => {
        switch (event.target.value) {
            case '8h':
                dateRange.setDateRange({ start: DateTime.now().minus({ hours: 8 }), end: null });
                break;
            case '24h':
                dateRange.setDateRange({ start: DateTime.now().minus({ hours: 24 }), end: null });
                break;
            case 'today':
                dateRange.setDateRange({ start: DateTime.now().startOf('day'), end: DateTime.now().endOf('day') });
                break;
            case 'yesterday':
                dateRange.setDateRange({
                    start: DateTime.now().minus({ days: 1 }).startOf('day'),
                    end: DateTime.now().minus({ days: 1 }).endOf('day')
                });
                break;
            case 'thisWeek':
                dateRange.setDateRange({ start: DateTime.now().startOf('week'), end: DateTime.now().endOf('week') });
                break;
            case 'lastWeek':
                dateRange.setDateRange({
                    start: DateTime.now().minus({ weeks: 1 }).startOf('week'),
                    end: DateTime.now().minus({ weeks: 1 }).endOf('week')
                });
                break;
            case 'other':
            default:
                dateRange.setCustomDateRange([DateTime.now().startOf('week'), DateTime.now().endOf('week')]);
                break;
        }

        dateRange.setSelectedValue(event.target.value);
    };

    const handleCustomDateStartChange = (value) => {
        dateRange.setCustomDateRange((previousValue) => {
            return [value, previousValue[1]];
        });
    };

    const handleCustomDateEndChange = (value) => {
        dateRange.setCustomDateRange((previousValue) => {
            return [previousValue[0], value];
        });
    };

    const handleGroupByChange = (event) => {
        dateRange.setGroupBy(event.target.value);
        dateRange.setSelectedGroupByValue(event.target.value);
    };

    const handleAutoRefreshChange = (event) => {
        dateRange.setRefreshDelay(event.target.checked ? 5000 : 0);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column'
            }}
            {...props}
        >
            {!_.isNil(dateRange.refreshDelay) && !_.isNil(dateRange.setRefreshDelay) && (
                <FormControl margin="dense">
                    <FormGroup>
                        <Tooltip title={t('experimentalWarning', { ns: 'translation' })}>
                            <FormControlLabel
                                control={<Checkbox checked={dateRange.refreshDelay > 0} onChange={handleAutoRefreshChange} />}
                                label={t('autoRefresh', { ns: 'statistics' })}
                            />
                        </Tooltip>
                    </FormGroup>
                </FormControl>
            )}
            <FormControl margin="dense">
                <FormLabel id="date-range-label">{t('dateRange', { ns: 'statistics' })}</FormLabel>
                <RadioGroup aria-labelledby="date-range-label" value={dateRange.selectedValue} onChange={handleDateRangeChange}>
                    <FormControlLabel value="8h" control={<Radio />} label={t('lastEightHours', { ns: 'statistics' })} />
                    <FormControlLabel value="24h" control={<Radio />} label={t('lastTwentyFourHours', { ns: 'statistics' })} />
                    <FormControlLabel value="today" control={<Radio />} label={t('today', { ns: 'statistics' })} />
                    <FormControlLabel value="yesterday" control={<Radio />} label={t('yesterday', { ns: 'statistics' })} />
                    <FormControlLabel value="thisWeek" control={<Radio />} label={t('thisWeek', { ns: 'statistics' })} />
                    <FormControlLabel value="lastWeek" control={<Radio />} label={t('lastWeek', { ns: 'statistics' })} />
                    <FormControlLabel value="other" control={<Radio />} label={t('other', { ns: 'statistics' })} />
                </RadioGroup>
                {dateRange.selectedValue === 'other' && (
                    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={i18n.language}>
                        <DateTimePicker
                            label={t('dateStart', { ns: 'statistics' })}
                            onChange={(value) => handleCustomDateStartChange(value)}
                            renderInput={(props) => <TextField margin="dense" {...props} />}
                            value={dateRange.customDateRange[0]}
                        />
                        <DateTimePicker
                            label={t('dateEnd', { ns: 'statistics' })}
                            onChange={(value) => handleCustomDateEndChange(value)}
                            renderInput={(props) => <TextField margin="dense" {...props} />}
                            value={dateRange.customDateRange[1]}
                        />
                    </LocalizationProvider>
                )}
            </FormControl>
            <FormControl margin="dense">
                {dateRange.availableGroups !== undefined && dateRange.availableGroups.length > 0 && (
                    <>
                        <InputLabel id="group-label">{t('groupBy', { ns: 'statistics' })}</InputLabel>
                        <Select
                            labelId="group-label"
                            label={t('groupBy', { ns: 'statistics' })}
                            onChange={handleGroupByChange}
                            value={dateRange.selectedGroupByValue}
                        >
                            <MenuItem value="default">{t('default', { ns: 'translation' })}</MenuItem>
                            {dateRange.availableGroups.map((group) => (
                                <MenuItem key={group} value={group}>
                                    {t(group, { ns: 'statistics' })}
                                </MenuItem>
                            ))}
                        </Select>
                    </>
                )}
            </FormControl>
        </Box>
    );
};

export default DateRangeForm;
