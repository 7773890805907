import { Card, CardContent, CardHeader } from '@mui/material';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
    BarController,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    Tooltip
} from 'chart.js';
import { DateTime } from 'luxon';
import Order from '../../api/endpoints/order';
import PropTypes from 'prop-types';
import stringToColour from '../../utils/stringToColour';
import { useTranslation } from 'react-i18next';
import useDateRange from '../../hooks/useDateRange';
import _ from 'lodash';
import useIntl from '../../hooks/useIntl';

function OrderStatistics({ event, statsResolution, cardContentProps, dateRangeOverride = null, adminMode = false }) {
    const { t, i18n } = useTranslation('statistics');
    const dateRange = useDateRange();
    const { currencyFormat, numberFormat } = useIntl();

    ChartJS.register(LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, LineController, BarController);

    const [volume, setVolume] = useState([]);
    const [labels, setLabels] = useState([]);
    const [transactionVolumeDataSet, setTransactionVolumeDataSet] = useState([]);
    const [profitsDataSets, setProfitsDataSets] = useState([]);

    useEffect(() => {
        populateVolume();
    }, [dateRange.dateRange, dateRangeOverride, statsResolution, event]);

    useEffect(() => {
        populateStatistics();
    }, [volume]);

    useEffect(() => {
        if (dateRange.refreshInterval !== null) {
            clearInterval(dateRange.refreshInterval);
            dateRange.setRefreshInterval(null);
        }

        if (dateRange.refreshDelay > 0) {
            dateRange.setRefreshInterval(
                setInterval(() => {
                    populateVolume();
                }, dateRange.refreshDelay)
            );
        }
    }, [dateRange.refreshDelay]);

    useEffect(() => {
        return () => {
            if (dateRange.refreshInterval !== null) {
                clearInterval(dateRange.refreshInterval);
                dateRange.setRefreshInterval(null);
            }
        };
    }, []);

    const populateVolume = async () => {
        const newDateRange = dateRangeOverride === null ? dateRange.getDateRange() : dateRangeOverride;

        const result = await Order.getStatisticsByInterval(
            statsResolution,
            newDateRange.start.toISO({ includeOffset: false }),
            newDateRange.end.toISO({ includeOffset: false }),
            event,
            adminMode,
            false
        );

        setVolume(result.result);
    };

    const populateStatistics = () => {
        const newLabels = [];
        const newData = {};
        const newDataSets = [];
        const newTransactionVolumeData = [];
        const newDateRange = dateRangeOverride === null ? dateRange.getDateRange() : dateRangeOverride;

        const spansMultipleDays = newDateRange.start.day !== newDateRange.end.day;
        const format = spansMultipleDays ? DateTime.DATETIME_SHORT : DateTime.TIME_24_SIMPLE;

        let currentDateTime = newDateRange.start;
        const minutesInRange = newDateRange.end.diff(newDateRange.start, 'minutes').minutes;

        // minutes in range / grouped minutes per datapoint = amount of total datapoints
        const dataPoints = Math.floor(minutesInRange / statsResolution);

        const types = _.uniqBy(volume, (e) => e.type)
            .flatMap((type) => type.type)
            .filter((type) => type !== null);

        for (let i = 0; i < dataPoints; i++) {
            // Add minutes equal to statsResolution for the next iteration's dateTime
            const nextDateTime = currentDateTime.plus({ minutes: statsResolution });
            const currentLocaleString = currentDateTime.toLocaleString(format);

            newLabels.push(currentLocaleString);

            // Filter all datapoints where their date is between current and next date
            const matches = volume.filter((e) => {
                const date = DateTime.fromISO(e.date);
                return date >= currentDateTime && date < nextDateTime && e.type !== null;
            });

            const matchesData = {};
            let volumeData = 0;

            matches.forEach((match) => {
                // Create empty new array if match.type hasn't been encountered yet
                if (!(match.type in matchesData)) {
                    matchesData[match.type] = parseFloat(match.total);
                } else {
                    matchesData[match.type] = matchesData[match.type] += parseFloat(match.total);
                }

                volumeData += match.volume;
            });

            for (const match in matchesData) {
                if (!(match in newData)) {
                    newData[match] = [];
                }

                newData[match].push(matchesData[match]);
            }

            // Get all types that do exist but aren't included in the current date range
            const missing = _.difference(
                types,
                matches.flatMap((match) => match.type)
            );

            // If no datapoints match the current date range, add 0 as transaction volume
            if (matches.length === 0) {
                newTransactionVolumeData.push(0);
            } else {
                newTransactionVolumeData.push(volumeData);
            }

            // Add a 0 datapoint to all types that aren't included in the current date range
            missing.forEach((type) => {
                if (!(type in newData)) {
                    newData[type] = [];
                }

                newData[type].push(0);
            });

            currentDateTime = nextDateTime;
        }

        // Create Chart.js datasets for each type
        Object.keys(newData).forEach((key) => {
            newDataSets.push({
                label: t(key, { ns: 'statistics' }),
                borderColor: stringToColour(key),
                backgroundColor: stringToColour(key),
                data: newData[key],
                type: 'bar',
                yAxisID: 'y1',
                stack: 'turnover'
            });
        });

        setLabels(newLabels);
        setProfitsDataSets(newDataSets);
        setTransactionVolumeDataSet(newTransactionVolumeData);
    };

    return (
        <Card>
            {event === undefined ? (
                <>
                    {dateRangeOverride === null ? (
                        <CardHeader
                            subheader={
                                dateRange.getDateRange().start.toLocaleString(DateTime.TIME_24_SIMPLE) +
                                '–' +
                                dateRange.getDateRange().end.toLocaleString(DateTime.TIME_24_SIMPLE)
                            }
                        />
                    ) : (
                        <CardHeader
                            subheader={
                                dateRangeOverride.start.toLocaleString(DateTime.TIME_24_SIMPLE) +
                                '–' +
                                dateRangeOverride.end.toLocaleString(DateTime.TIME_24_SIMPLE)
                            }
                        />
                    )}
                </>
            ) : (
                <CardHeader subheader={event.event.name} />
            )}

            <CardContent {...cardContentProps}>
                <Bar
                    style={{
                        minHeight: '15rem'
                    }}
                    height={450}
                    options={{
                        locale: i18n.language,
                        maintainAspectRatio: false,
                        scales: {
                            y: {
                                type: 'linear',
                                display: true,
                                position: 'left'
                            },
                            y1: {
                                type: 'linear',
                                display: true,
                                position: 'right',
                                grid: {
                                    drawOnChartArea: false
                                }
                            }
                        },
                        plugins: {
                            tooltip: {
                                mode: 'index',
                                axis: 'xy',
                                intersect: false,
                                callbacks: {
                                    label: (context) => {
                                        const type = context.dataset.label;
                                        const value = context.dataset.data[context.dataIndex];

                                        if (context.datasetIndex !== profitsDataSets.length) {
                                            if (type === t('transactionVolume', { ns: 'statistics' })) {
                                                return [`${type}: ${numberFormat.format(value)}`, ''];
                                            }

                                            return `${type}: ${currencyFormat.format(value)}`;
                                        } else {
                                            let total = 0.0;

                                            for (let i = 0; i < profitsDataSets.length; i++) {
                                                total += profitsDataSets[i].data[context.dataIndex];
                                            }

                                            return [
                                                `${type}: ${currencyFormat.format(value)}`,
                                                '',
                                                `Totaal: ${currencyFormat.format(total)}`
                                            ];
                                        }
                                    }
                                }
                            }
                        }
                    }}
                    data={{
                        labels,
                        datasets: [
                            {
                                label: t('transactionVolume', { ns: 'statistics' }),
                                borderColor: 'rgb(240,105,14)',
                                backgroundColor: 'rgba(240,105,14, 0.5)',
                                data: transactionVolumeDataSet,
                                type: 'line',
                                yAxisID: 'y'
                            },
                            ...profitsDataSets
                        ]
                    }}
                />
            </CardContent>
        </Card>
    );
}

OrderStatistics.propTypes = {
    event: PropTypes.object,
    statsResolution: PropTypes.number.isRequired,
    adminMode: PropTypes.bool,
    dateRangeOverride: PropTypes.object,
    cardContentProps: PropTypes.object
};

export default OrderStatistics;
