import { useEffect, useState } from 'react';
import fetcher from '../../fetcher';
import { Card, CardContent, CardHeader } from '@mui/material';
import { DateTime } from 'luxon';
import { Pie } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import stringToColour from '../../utils/stringToColour';
import useDateRange from '../../hooks/useDateRange';
import PropTypes from 'prop-types';
import useIntl from '../../hooks/useIntl';

const PaymentMethodPie = ({ event, cardContentProps, admin = false }) => {
    const { t, i18n } = useTranslation(['translation', 'statistics', 'orders']);
    const dateRange = useDateRange();

    const { currencyFormat } = useIntl();

    ChartJS.register(ArcElement, Tooltip, Legend);

    const [paymentMethods, setPaymentMethods] = useState([]);
    const [paymentMethodsDataSet, setPaymentMethodsDataSet] = useState([]);
    const [labels, setLabels] = useState([]);
    const [colours, setColours] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        populatePaymentMethods();
    }, [dateRange.dateRange, event]);

    useEffect(() => {
        populateStatistics();
    }, [paymentMethods]);

    useEffect(() => {
        return () => {
            dateRange.setRefreshDelay(0);

            if (dateRange.refreshInterval !== null) {
                clearInterval(dateRange.refreshInterval);
                dateRange.setRefreshInterval(null);
            }
        };
    }, []);

    useEffect(() => {
        if (dateRange.refreshInterval !== null) {
            clearInterval(dateRange.refreshInterval);
            dateRange.setRefreshInterval(null);
        }

        if (dateRange.refreshDelay > 0) {
            dateRange.setRefreshInterval(
                setInterval(() => {
                    populatePaymentMethods();
                }, dateRange.refreshDelay)
            );
        }
    }, [dateRange.refreshDelay]);

    const populatePaymentMethods = () => {
        setLoading(true);
        const newDateRange = dateRange.getDateRange();

        const startDate = newDateRange.start?.toISO({
            includeOffset: false
        });

        const endDate = newDateRange.end?.toISO({ includeOffset: false });

        const args = [`startDate=${startDate}`, `endDate=${endDate}`];

        if (event !== undefined) args.push(`eventDateId=${event.id}`);

        fetcher(`v1/${admin ? 'admin' : 'order'}/statistic/paymentMethod?${args.join('&')}`)
            .then(async (result) => {
                result = await result.json();

                if (result) {
                    setPaymentMethods(result);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const populateStatistics = () => {
        const newLabels = [];
        const newData = [];
        const newColours = [];

        paymentMethods.forEach((paymentMethod) => {
            const name = t(paymentMethod.name, { ns: 'orders' });

            newLabels.push(`${name} – ${currencyFormat.format(paymentMethod.volume)}`);
            newData.push(paymentMethod.volume);
            newColours.push(stringToColour(name));
        });

        setColours(newColours);
        setLabels(newLabels);
        setPaymentMethodsDataSet(newData);
    };

    const getSubheader = () => {
        if (event !== undefined) return event.event.name;

        return (
            dateRange.getDateRange().start.toLocaleString(DateTime.DATETIME_SHORT) +
            '–' +
            dateRange.getDateRange().end.toLocaleString(DateTime.DATETIME_SHORT)
        );
    };

    return (
        <Card>
            <CardHeader title={t('profitsByPaymentMethod', { ns: 'statistics' })} subheader={<>{getSubheader()}</>} />
            <CardContent {...cardContentProps}>
                <Pie
                    options={{
                        locale: i18n.language
                    }}
                    data={{
                        labels: labels,
                        datasets: [
                            {
                                data: paymentMethodsDataSet,
                                backgroundColor: colours
                            }
                        ]
                    }}
                />
            </CardContent>
        </Card>
    );
};

PaymentMethodPie.propTypes = {
    admin: PropTypes.bool,
    event: PropTypes.object,
    cardContentProps: PropTypes.object
};

export default PaymentMethodPie;
