import { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const TitleContext = createContext({});

export function TitleProvider({ children }) {
    const [title, setTitle] = useState();

    const testEnv = process.env.REACT_APP_ENVIRONMENT !== 'production';

    function changeTitle(newTitle) {
        setTitle(newTitle);

        if (testEnv) {
            document.title = `${newTitle} (Test environment) - Paytree`;
        } else {
            document.title = `${newTitle} - Paytree`;
        }
    }

    const memoedValue = useMemo(
        () => ({
            changeTitle,
            title
        }),
        [title]
    );

    return <TitleContext.Provider value={memoedValue}>{children}</TitleContext.Provider>;
}

TitleProvider.propTypes = {
    children: PropTypes.object
};

export default function useTitle() {
    return useContext(TitleContext);
}
