import React, { lazy, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuthProvider } from './hooks/useAuth';
import 'react-toastify/dist/ReactToastify.min.css';
import { toast, ToastContainer } from 'react-toastify';
import { IntlProvider } from './hooks/useIntl';
import { useTranslation } from 'react-i18next';
import { DateRangeProvider } from './hooks/useDateRange';
import SelectEvent from './components/finance/SelectEvent';
import FinanceData from './components/finance/FinanceData';
import UsedBalanceCards from './components/admin/UsedBalanceCards';
import Theme from './components/misc/Theme';
import FullScreen from './components/admin/statistics/full-screen';

//region lazy imports
const Loading = lazy(() => import('./components/misc/Loading'));
const Login = lazy(() => import('./components/auth/Login'));
const Totp = lazy(() => import('./components/auth/Totp'));
const SignUp = lazy(() => import('./components/auth/SignUp'));
const VerifyEmail = lazy(() => import('./components/auth/VerifyEmail'));
const AuthenticatedRoute = lazy(() => import('./components/auth/AuthenticatedRoute'));
const Layout = lazy(() => import('./components/layouts/Layout'));
const Dashboard = lazy(() => import('./components/Dashboard'));
const Products = lazy(() => import('./components/products/index'));
const ProductCategories = lazy(() => import('./components/ProductCategories'));
const Registers = lazy(() => import('./components/cash_registers/Index'));
const ManageInventory = lazy(() => import('./components/cash_registers/ManageInventory'));
const QrCodePdf = lazy(() => import('./components/misc/QrCodePdf'));
const Devices = lazy(() => import('./components/Devices'));
const Terminals = lazy(() => import('./components/Terminals'));
const Invoices = lazy(() => import('./components/Invoices'));
const Events = lazy(() => import('./components/events/Index'));
const StatisticCharts = lazy(() => import('./components/StatisticCharts'));
const TransactionOverview = lazy(() => import('./components/TransactionOverview'));
const TransactionLog = lazy(() => import('./components/TransactionLog'));
const ProductLog = lazy(() => import('./components/ProductLog'));
const Users = lazy(() => import('./components/admin/Users'));
const Roles = lazy(() => import('./components/admin/Roles'));
const Printers = lazy(() => import('./components/admin/Printers'));
const Organisations = lazy(() => import('./components/admin/Organisations'));
const Log = lazy(() => import('./components/admin/AccessLog'));
const UserSettings = lazy(() => import('./components/user/UserSettings'));
const OrganisationSettings = lazy(() => import('./components/organisation/OrganisationSettings'));
const Logout = lazy(() => import('./components/auth/Logout'));
const AdminStatistics = lazy(() => import('./components/admin/AdminStatistics'));
const BalanceCards = lazy(() => import('./components/BalanceCards'));
const RequestPasswordReset = lazy(() => import('./components/auth/RequestPasswordReset'));
const ResetPassword = lazy(() => import('./components/auth/ResetPassword'));
const InventoryLocations = lazy(() => import('./components/inventory/Index'));
const CreateInventoryLocation = lazy(() => import('./components/inventory/Create'));
const EditInventoryLocation = lazy(() => import('./components/inventory/Edit'));
const OrdersPerCashRegister = lazy(() => import('./components/OrdersPerCashRegister'));
const DevicesMap = lazy(() => import('./components/DevicesMap'));

const ඞ = () => {
    const { t } = useTranslation(['translation']);

    const isOnNetwork = window.location.host === 'app.paytree-network.nl';

    useEffect(() => {
        if (isOnNetwork) {
            window.location.replace(window.location.href.replace('https://app.paytree-network.nl', 'https://app.paytree.nl'));
        }
    }, [isOnNetwork]);

    useEffect(() => {
        navigator.serviceWorker?.getRegistration(`${process.env.PUBLIC_URL}/service-worker.js`).then((registration) => {
            if (registration) {
                registration.onupdatefound = () => {
                    const installingWorker = registration.installing;
                    if (installingWorker != null) {
                        installingWorker.onstatechange = () => {
                            if (installingWorker.state === 'installed') {
                                if (registration && registration.waiting) {
                                    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                                }

                                toast.info(t('updateAvailable', { ns: 'translation' }), {
                                    autoClose: false,
                                    hideProgressBar: true,
                                    closeOnClick: false
                                });
                            }
                        };
                    }
                };
            }
        });
    }, []);

    return (
        <div
            className="App"
            style={{
                height: '100%'
            }}
        >
            <IntlProvider>
                <Theme>
                    <React.Suspense fallback={<Loading />}>
                        <AuthProvider>
                            <DateRangeProvider>
                                <Routes>
                                    <Route path="/login" element={<Login />} />
                                    <Route path="/login/totp" element={<Totp />} />
                                    <Route path="/signUp" element={<SignUp />} />
                                    <Route path="/verifyEmail" element={<VerifyEmail />} />
                                    <Route path="/resetPassword" element={<RequestPasswordReset />} />
                                    <Route path="/resetPassword/:token" element={<ResetPassword />} />

                                    <Route element={<AuthenticatedRoute />}>
                                        <Route path="/admin/statistics/fullscreen" element={<FullScreen />} />
                                        <Route path="/" element={<Layout drawerWidth={250} />}>
                                            <Route index element={<Dashboard />} />

                                            <Route path="dashboard" element={<Dashboard />} />
                                            <Route path="products" element={<Products />} />
                                            <Route path="productCategories" element={<ProductCategories />} />
                                            <Route path="cashRegisterOrders" element={<OrdersPerCashRegister />} />
                                            <Route path="inventory">
                                                <Route path="locations">
                                                    <Route index element={<InventoryLocations />} />
                                                    <Route path="create" element={<CreateInventoryLocation />} />
                                                    <Route path=":locationId">
                                                        <Route path="edit" element={<EditInventoryLocation />} />
                                                    </Route>
                                                </Route>
                                            </Route>
                                            <Route path="registers">
                                                <Route index element={<Registers />} />
                                                <Route path=":cashRegisterId">
                                                    <Route path="inventory" element={<ManageInventory />} />
                                                </Route>
                                            </Route>
                                            <Route path="registers/printQrCodes/:codes" element={<QrCodePdf />} />
                                            <Route path="devices" element={<Devices />} />
                                            <Route path="devices/map" element={<DevicesMap />} />
                                            <Route path="terminals" element={<Terminals />} />
                                            <Route path="invoices" element={<Invoices />} />
                                            <Route path="events" element={<Events />} />
                                            <Route path="statistics">
                                                <Route path="charts" element={<StatisticCharts />} />
                                                <Route path="overview" element={<TransactionOverview />} />
                                                <Route path="log" element={<TransactionLog />} />
                                                <Route path="productLog" element={<ProductLog />} />
                                                <Route path="balanceCards" element={<BalanceCards />} />
                                                <Route path="finance">
                                                    <Route index element={<SelectEvent />} />
                                                    <Route path=":eventId" element={<FinanceData />} />
                                                </Route>
                                            </Route>
                                            <Route path="admin">
                                                {/*<Route path="settings" element={<AdminSettings />} />*/}
                                                <Route path="statistics" element={<AdminStatistics />} />
                                                <Route path="balanceCards" element={<UsedBalanceCards />} />
                                                <Route path="users" element={<Users />} />
                                                <Route path="roles" element={<Roles />} />
                                                <Route path="printers" element={<Printers />} />
                                                <Route path="organisations" element={<Organisations />} />
                                                <Route path="log" element={<Log />} />
                                            </Route>
                                            <Route path="user/settings" element={<UserSettings />} />
                                            <Route path="organisation/settings" element={<OrganisationSettings />} />
                                            <Route path="logout" element={<Logout />} />
                                        </Route>
                                    </Route>
                                </Routes>
                            </DateRangeProvider>
                        </AuthProvider>
                    </React.Suspense>

                    <ToastContainer
                        position="bottom-left"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Theme>
            </IntlProvider>
        </div>
    );
};

export default ඞ;
