import fetcher from '../../fetcher';

export default class User {
    static getUserSettings(forceRefresh, options) {
        options = {
            ...options,
            method: 'GET'
        };

        return fetcher('v1/user/settings', forceRefresh, options).then(async (result) => {
            if (result && result.ok) {
                const date = new Date(result.headers.get('date')).toLocaleString();
                result = await result.json();

                return {
                    result: result,
                    lastUpdated: date
                };
            }
        });
    }
}
