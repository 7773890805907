import { Box } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import GridToolbar from '../misc/GridToolbar';
import PropTypes from 'prop-types';

function FullHeightDataGrid(props) {
    const { lastUpdated, customToolbarButtons, adjustForFab, components = {} } = props;

    return (
        <>
            <Box height={adjustForFab ?? true ? '85vh' : '90vh'} maxWidth="100%">
                <DataGridPremium
                    {...props}
                    sx={{
                        backgroundColor: '#FFFFFF'
                    }}
                    slots={{
                        ...components,
                        toolbar: () => GridToolbar({ lastUpdated: lastUpdated, customToolbarButtons: customToolbarButtons })
                    }}
                    pagination
                    pageSizeOptions={[25, 50, 100, 250, 500]}
                />
            </Box>
        </>
    );
}

FullHeightDataGrid.propTypes = {
    lastUpdated: PropTypes.string,
    customToolbarButtons: PropTypes.array,
    adjustForFab: PropTypes.bool,
    components: PropTypes.object
};

export default FullHeightDataGrid;
