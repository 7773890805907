import fetcher from '../../fetcher';
import { DateTime } from 'luxon';
import ApiHelper from '../apiHelper';

export default class Order {
    static getAllOrders(startDate, endDate, groupBy, limit, page, adminMode, forceRefresh, givenOptions, filter = null) {
        const headers = {
            'X-Pagination': `${limit};${page}`
        };

        const options = {
            ...givenOptions,
            method: 'GET',
            headers: headers
        };

        const queryString = `?${groupBy !== null && groupBy !== 'default' ? `groupBy=${groupBy}&` : ''}${
            startDate !== null ? `startDate=${startDate}&` : ''
        }${endDate !== null ? `endDate=${endDate}&` : ''}${filter !== null ? `filter=${filter}` : ''}`;

        return fetcher(`v1/${adminMode ? 'admin/statistic/' : ''}order${queryString}`, forceRefresh, options).then(async (result) => {
            if (result) {
                // TODO: Fix http date header being null
                const date = DateTime.fromHTTP(result.headers.get('date')).toLocaleString();
                result = await result.json();

                return {
                    result: result.data,
                    total: result.total,
                    rowCount: result.rowCount,
                    lastUpdated: date
                };
            }
        });
    }

    static getStatisticsByProduct(startDate, endDate, productTypeId, forceRefresh, options) {
        options = {
            ...options,
            method: 'GET'
        };

        const params = {
            type: 'product',
            startDate: startDate,
            endDate: endDate,
            productTypeId: productTypeId
        };

        const queryString = ApiHelper.buildQueryString(params, { excludeNulls: true });

        return fetcher(`v1/order/statistic?${queryString}`, forceRefresh, options).then(async (result) => {
            if (result) {
                // TODO: Fix http date header being null
                const date = DateTime.fromHTTP(result.headers.get('date')).toLocaleString();
                result = await result.json();

                return {
                    result: result,
                    lastUpdated: date
                };
            }
        });
    }

    static getStatisticsByProductType(startDate, endDate, event, forceRefresh, options) {
        options = {
            ...options,
            method: 'GET'
        };

        const args = [];

        if (startDate !== null) {
            args.push(`startDate=${startDate}`);
        }

        if (endDate !== null) {
            args.push(`endDate=${endDate}`);
        }

        if (event !== undefined) {
            args.push(`eventDateId=${event.id}`);
        }

        return fetcher(`v1/order/statistic?type=productType&${args.join('&')}`, forceRefresh, options).then(async (result) => {
            if (result) {
                // TODO: Fix http date header being null
                const date = DateTime.fromHTTP(result.headers.get('date')).toLocaleString();
                result = await result.json();

                return {
                    result: result,
                    lastUpdated: date
                };
            }
        });
    }

    static getStatisticsByInterval(interval, startDate, endDate, event, adminMode, forceRefresh, options) {
        options = {
            ...options,
            method: 'GET'
        };

        const args = [];

        if (startDate !== null) {
            args.push(`startDate=${startDate}`);
        }

        if (endDate !== null) {
            args.push(`endDate=${endDate}`);
        }

        if (event !== undefined) {
            args.push(`eventDateId=${event.id}`);
        }

        const endpoint = adminMode ? 'v1/admin/statistic' : 'v1/order/statistic';

        return fetcher(`${endpoint}?type=interval&interval=${interval ?? '5'}&${args.join('&')}`, forceRefresh, options).then(
            async (result) => {
                if (result) {
                    // TODO: Fix http date header being null
                    const date = DateTime.fromHTTP(result.headers.get('date')).toLocaleString();
                    result = await result.json();

                    return {
                        result: result,
                        lastUpdated: date
                    };
                }
            }
        );
    }

    static getOrderProducts(startDate, endDate, groupBy, groupByCashRegister, forceRefresh, options) {
        options = {
            ...options,
            method: 'GET'
        };

        const queryString = `?${groupBy !== null && groupBy !== 'default' ? `groupBy=${groupBy}&` : ''}${
            startDate !== null ? `startDate=${startDate}&` : ''
        }${endDate !== null ? `endDate=${endDate}&` : ''}${groupByCashRegister !== null ? `groupBy=${groupByCashRegister}` : ''}`;

        return fetcher(`v1/order/product${queryString}`, forceRefresh, options).then(async (result) => {
            if (result) {
                // TODO: Fix http date header being null
                const date = DateTime.fromHTTP(result.headers.get('date')).toLocaleString();
                result = await result.json();

                return {
                    result: result,
                    lastUpdated: date
                };
            }
        });
    }

    static newOrder(forceRefresh, options) {
        return fetcher('v1/order', forceRefresh, options, 'Order', false).then(async (result) => {
            if (result) {
                // TODO: Fix http date header being null
                // const date = DateTime.fromHTTP(result.headers.get('date')).toLocaleString();
                // result = await result.json();
                //
                // return {
                //     result: result
                //     // lastUpdated: date
                // };
            }
        });
    }

    static getCancelledOrders(options) {
        options = {
            ...options,
            method: 'GET'
        };

        return fetcher('v1/order/check-for-spike', options).then(async (result) => {
            if (result) {
                result = await result;
                return {
                    result: result
                    // lastUpdated: date
                };
            }
        });
    }
}
