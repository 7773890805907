class ApiHelper {
    static buildQueryString(params, { excludeNulls = true }) {
        const paramsArray = [];

        Object.keys(params).forEach((key) => {
            if ((excludeNulls && params[key] !== null) || !excludeNulls) {
                paramsArray.push(`${key}=${params[key]}`);
            }
        });

        return paramsArray.join('&');
    }
}

export default ApiHelper;
