import { Box, Drawer, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import useDateRange from '../../hooks/useDateRange';
import DateRangeForm from './DateRangeForm';

function DateRangeOptions() {
    const { t } = useTranslation(['statistics', 'translation']);

    const dateRange = useDateRange();

    useEffect(() => {
        if (dateRange.selectedValue === 'other' && !_.isNil(dateRange.customDateRange[0]) && !_.isNil(dateRange.customDateRange[1])) {
            dateRange.setDateRange({ start: dateRange.customDateRange[0], end: dateRange.customDateRange[1] });
        }
    }, [dateRange.customDateRange, dateRange.selectedValue]);

    const toggleDrawer = (value = null) => {
        dateRange.setExpanded((oldValue) => value ?? !oldValue);
    };

    return (
        <>
            <Box
                sx={{
                    display: { xs: 'block', lg: 'none' }
                }}
            >
                <Tooltip title={t('moreOptions', { ns: 'translation' })}>
                    <IconButton
                        onClick={toggleDrawer}
                        size="large"
                        sx={(theme) => ({
                            backgroundColor: theme.palette.primary.main,
                            color: 'white',
                            position: 'absolute',
                            top: '50%',
                            right: 0,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.dark,
                                color: 'white'
                            }
                        })}
                    >
                        {dateRange.expanded ? <ChevronRight fontSize="inherit" /> : <ChevronLeft fontSize="inherit" />}
                    </IconButton>
                </Tooltip>
                <Drawer open={dateRange.expanded} onClose={() => toggleDrawer(false)} anchor="right" variant="temporary">
                    <Box sx={{ p: 1 }}>
                        <DateRangeForm />
                    </Box>
                </Drawer>
            </Box>
            <Box
                sx={{
                    display: { xs: 'none', lg: 'block' }
                }}
            >
                <DateRangeForm />
            </Box>
        </>
    );
}

export default DateRangeOptions;
