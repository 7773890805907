import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import fetcher from '../../fetcher';
import { Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getPaymentProfile } from '../misc/utilities';
import { getPaymentProfileName } from '../misc/PaymentProfileMap';
import useIntl from '../../hooks/useIntl';

function FinanceData() {
    const { t } = useTranslation(['translation', 'finance', 'orders']);
    const { eventId } = useParams();
    const { currencyFormat } = useIntl();

    const [loading, setLoading] = useState(false);
    const [financeData, setFinanceData] = useState({});
    const [total, setTotal] = useState({});

    useEffect(() => {
        setLoading(true);

        fetcher(`/v1/finance?eventId=${eventId}`, true, {
            method: 'GET'
        })
            .then(async (result) => {
                if (result) {
                    result = await result.json();

                    setFinanceData(result);

                    let total = 0;
                    let totalFees = 0;

                    Object.keys(result).forEach((paymentMethodId) => {
                        total += parseFloat(result[paymentMethodId].total);
                        totalFees += parseFloat(result[paymentMethodId].total) * 0.0185;
                    });

                    setTotal({ total: total, fees: totalFees });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [eventId]);

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Betaalmethode</TableCell>
                        <TableCell>Totalen</TableCell>
                        <TableCell>Kosten</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <>
                            <TableRow>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                                <TableCell>
                                    <Skeleton />
                                </TableCell>
                            </TableRow>
                        </>
                    ) : (
                        <>
                            {Object.keys(financeData).map((paymentMethodId) => (
                                <TableRow key={paymentMethodId}>
                                    <TableCell>{getPaymentProfile(paymentMethodId, null, t)}</TableCell>
                                    <TableCell>{t(getPaymentProfileName(paymentMethodId), { ns: 'orders' })}</TableCell>
                                    <TableCell>{currencyFormat.format(financeData[paymentMethodId]['total'])}</TableCell>
                                    <TableCell>{currencyFormat.format(financeData[paymentMethodId]['total'] * 0.0185)}</TableCell>
                                </TableRow>
                            ))}

                            {Object.keys(total).length > 0 && (
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        <b>Totaal</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>{currencyFormat.format(total.total)}</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>{currencyFormat.format(total.fees)}</b>
                                    </TableCell>
                                </TableRow>
                            )}
                        </>
                    )}
                </TableBody>
            </Table>
        </>
    );
}

export default FinanceData;
