import { lazy, useEffect, useState } from 'react';
import Order from '../../api/endpoints/order';
import { useTranslation } from 'react-i18next';
import useTitle from '../../hooks/useTitle';
import TransactionLogTable from '../tables/TransactionLogTable';
import TransactionLogGroupedTable from '../tables/TransactionLogGroupedTable';
import useDateRange from '../../hooks/useDateRange';
import useIntl from '../../hooks/useIntl';
import FooterTotals from '../tables/FooterTotals';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Typography } from '@mui/material';
import fetcher from '../../fetcher';
import { LoadingButton } from '@mui/lab';

const ViewTransaction = lazy(() => import('../dialogues/ViewTransaction'));

function TransactionLogGrid({ adminMode = false }) {
    const { t } = useTranslation(['translation', 'navbar', 'orders', 'terminals', 'statistics']);
    const { changeTitle } = useTitle();
    const dateRange = useDateRange();
    const { currencyFormat } = useIntl();

    const [orders, setOrders] = useState([]);
    const [groupedOrders, setGroupedOrders] = useState([]);
    const [loadingTransactions, setLoadingTransactions] = useState(false);
    const [viewTransactionOpen, setViewTransactionOpen] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState();

    const [loadingExport, setLoadingExport] = useState(false);

    const [rowCount, setRowCount] = useState(0);

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 500
    });

    useEffect(() => {
        changeTitle(t('transactionLog', { ns: 'navbar' }));
    }, [changeTitle, t]);

    useEffect(() => {
        dateRange.setAvailableGroups(['CASH_REGISTER', 'PRODUCT', 'TERMINAL', 'STATUS', 'PAYMENT_PROFILE_ID']);

        return () => {
            dateRange.setAvailableGroups([]);
            dateRange.setRefreshDelay(0);

            if (dateRange.refreshInterval !== null) {
                clearInterval(dateRange.refreshInterval);
                dateRange.setRefreshInterval(null);
            }
        };
    }, []);

    useEffect(() => {
        setPaginationModel((prev) => {
            const newModel = { ...prev };

            newModel.page = 0;
            return newModel;
        });
    }, [dateRange.groupBy]);

    useEffect(() => {
        populateOrders();
    }, [dateRange.dateRange, dateRange.groupBy]);

    useEffect(() => {
        if (dateRange.refreshInterval !== null) {
            clearInterval(dateRange.refreshInterval);
            dateRange.setRefreshInterval(null);
        }

        if (dateRange.refreshDelay > 0) {
            dateRange.setRefreshInterval(
                setInterval(() => {
                    populateOrders();
                }, dateRange.refreshDelay)
            );
        }
    }, [dateRange.refreshDelay]);

    const populateOrders = async () => {
        setLoadingTransactions(true);
        const newDateRange = dateRange.getDateRange();

        const result = await Order.getAllOrders(
            newDateRange.start.toISO({ includeOffset: false }),
            newDateRange.end.toISO({ includeOffset: false }),
            dateRange.groupBy,
            paginationModel.pageSize,
            paginationModel.page,
            adminMode,
            false
        );

        if (result) {
            setRowCount(result.rowCount);

            if (dateRange.groupBy !== null && dateRange.groupBy !== 'default') {
                setGroupedOrders(result.result);
            } else {
                setOrders(result.result);
            }
        }

        setLoadingTransactions(false);
    };

    const onViewTransactionClose = () => {
        setViewTransactionOpen(false);
    };

    const handleTransactionClick = (row) => {
        const transaction = _.find(orders, (order) => order.id === row.id);
        setSelectedTransaction(transaction);
        setViewTransactionOpen(true);
    };

    const handleExport = async () => {
        const newDateRange = dateRange.getDateRange();

        const startDate = newDateRange.start.toISO({ includeOffset: false });
        const endDate = newDateRange.end.toISO({ includeOffset: false });

        const groupBy = dateRange.groupBy;

        setLoadingExport(true);

        const queryString = `?${groupBy !== null && groupBy !== 'default' ? `groupBy=${groupBy}&` : ''}${
            startDate !== null ? `startDate=${startDate}&` : ''
        }${endDate !== null ? `endDate=${endDate}&` : ''}`;

        fetcher(`v1/order/export${queryString}`, true)
            .then((res) => res.blob())
            .then((blob) => {
                const file = window.URL.createObjectURL(blob);
                window.location.assign(file);
            })
            .finally(() => {
                setLoadingExport(false);
            });
    };

    return (
        <>
            {selectedTransaction && (
                <ViewTransaction open={viewTransactionOpen} onClose={onViewTransactionClose} transaction={selectedTransaction} />
            )}
            {dateRange.groupBy !== null && dateRange.groupBy !== 'default' ? (
                <TransactionLogGroupedTable
                    paginationMode="server"
                    rowCount={rowCount}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    rows={groupedOrders}
                    loading={loadingTransactions}
                    groupBy={dateRange.groupBy}
                />
            ) : (
                <>
                    <LoadingButton loading={loadingExport} onClick={handleExport}>
                        Huidige selectie exporteren
                    </LoadingButton>
                    <br />
                    <Typography variant="muted">De export knop in de tabel exporteert alleen de huidige pagina.</Typography>

                    <TransactionLogTable
                        paginationMode="server"
                        rowCount={rowCount}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        handleTransactionClick={handleTransactionClick}
                        rows={orders}
                        loading={loadingTransactions}
                        adminMode={adminMode}
                        components={{
                            Footer: FooterTotals
                        }}
                    />
                </>
            )}
        </>
    );
}

TransactionLogGrid.propTypes = {
    adminMode: PropTypes.bool
};

export default TransactionLogGrid;
