import fetcher from '../fetcher';
import { toast } from 'react-toastify';

export async function login(params) {
    const options = {
        method: 'POST',
        body: JSON.stringify(params)
    };

    return fetcher('/v1/auth/login', false, options, undefined, false).then(async (response) => {
        if (response) {
            if (response.status === 412) {
                return `totpChallenge:${await response.text()}`;
            } else if (response.status === 401) {
                return 'unauthorized';
            }

            response = await response.json();

            const user = response.user;

            localStorage.setItem('user', JSON.stringify(user));

            localStorage.setItem(
                'authToken',
                JSON.stringify({
                    token: response.authToken,
                    expires: response.authTokenExpires
                })
            );

            localStorage.setItem(
                'refreshToken',
                JSON.stringify({
                    token: response.refreshToken,
                    expires: response.refreshTokenExpires
                })
            );

            return user;
        }
    });
}

/**
 * @param {string|null} role
 *
 * @returns {Promise<boolean>}
 */
export async function check(role = null) {
    try {
        let authToken = localStorage.getItem('authToken');

        if (authToken === null) {
            return false;
        }

        authToken = JSON.parse(authToken);

        const options = {
            method: 'POST',
            body: JSON.stringify({
                authToken: authToken.token,
                role: role
            })
        };

        return fetcher('/v1/auth/check', false, options).then(async (response) => {
            if (response) {
                if (response.status < 200 || response.status >= 300) {
                    return false;
                }

                response = await response.json();

                localStorage.setItem('user', JSON.stringify(response));

                return response;
            }

            return false;
        });
    } catch (e) {
        toast.error(e);

        return false;
    }
}

export async function logout() {
    fetcher('/v1/auth/logout').then(() => {
        destroySession();
    });
}

export function destroySession() {
    localStorage.clear();
}
