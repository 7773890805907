import { useTranslation } from 'react-i18next';
import { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const IntlContext = createContext({});

export function IntlProvider({ children }) {
    const { i18n } = useTranslation();

    const numberFormat = useMemo(
        () => new Intl.NumberFormat(i18n.language, { style: 'decimal', maximumFractionDigits: 2 }),
        [i18n.language]
    );

    const intFormat = useMemo(
        () =>
            new Intl.NumberFormat(i18n.language, {
                style: 'decimal',
                maximumFractionDigits: 0
            }),
        [i18n.language]
    );

    const currencyFormat = useMemo(
        () =>
            new Intl.NumberFormat(i18n.language, {
                style: 'currency',
                currency: 'EUR',
                maximumFractionDigits: 2
            }),
        [i18n.language]
    );

    const percentFormat = useMemo(
        () => new Intl.NumberFormat(i18n.language, { style: 'percent', maximumFractionDigits: 2 }),
        [i18n.language]
    );

    // thing1, thing2 and thing3
    const conjunctionListFormat = useMemo(() => new Intl.ListFormat(i18n.language, { type: 'conjunction' }), [i18n.language]);

    // thing1, thing2 or thing3
    const disjunctionListFormat = useMemo(() => new Intl.ListFormat(i18n.language, { type: 'disjunction' }), [i18n.language]);

    const memoedValue = useMemo(
        () => ({
            numberFormat,
            currencyFormat,
            percentFormat,
            intFormat,
            conjunctionListFormat,
            disjunctionListFormat
        }),
        [numberFormat, currencyFormat, percentFormat, intFormat, conjunctionListFormat, disjunctionListFormat]
    );

    return <IntlContext.Provider value={memoedValue}>{children}</IntlContext.Provider>;
}

IntlProvider.propTypes = {
    children: PropTypes.object
};

export default function useIntl() {
    return useContext(IntlContext);
}
