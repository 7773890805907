import useDateRange from '../../hooks/useDateRange';
import Grid2 from '@mui/material/Unstable_Grid2';
import DateRangeOptions from '../misc/DateRangeOptions';
import { useCallback, useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import fetcher from '../../fetcher';
import { DateTime } from 'luxon';

function UsedBalanceCards() {
    const [events, setEvents] = useState([]);

    const { dateRange } = useDateRange();

    const populateEvents = useCallback(() => {
        const query = [];

        if (dateRange.start !== null) {
            query.push(`startDate=${dateRange.start.toISO({ includeOffset: false })}`);
        }

        if (dateRange.end !== null) {
            query.push(`endDate=${dateRange.end.toISO({ includeOffset: false })}`);
        } else {
            query.push(`endDate=${DateTime.now().toISO({ includeOffset: false })}`);
        }

        const queryParams = query.join('&');

        fetcher(`/v1/admin/event/balanceCards?${queryParams}`, true, { method: 'GET' }).then(async (result) => {
            if (result) {
                result = await result.json();

                setEvents(result);
            }
        });
    }, [dateRange.start, dateRange.end]);

    useEffect(() => {
        populateEvents();
    }, [dateRange.start, dateRange.end, populateEvents]);

    return (
        <Grid2 container spacing={2}>
            <Grid2 xs={11} lg={10}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Event</TableCell>
                            <TableCell>Organisation</TableCell>
                            <TableCell>Unique cards</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {events.map((event) => (
                            <TableRow key={event.event}>
                                <TableCell>{event.event}</TableCell>
                                <TableCell>{event.organisation}</TableCell>
                                <TableCell>{event.uniqueCards}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid2>
            <Grid2 xs={1} lg={2}>
                <DateRangeOptions />
            </Grid2>
        </Grid2>
    );
}

export default UsedBalanceCards;
