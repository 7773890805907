import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useIntl from '../../hooks/useIntl';
import PropTypes from 'prop-types';
import { getPaymentProfile, getStatusColor } from '../misc/utilities';
import { Chip } from '@mui/material';
import FullHeightDataGrid from './FullHeightDataGrid';
import FooterTotals from './FooterTotals';
import { getPaymentProfileName } from '../misc/PaymentProfileMap';

function TransactionLogGroupedTable(props) {
    const { rows, loading, groupBy } = props;

    const { t } = useTranslation(['translation', 'orders']);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({
        splitValue: false,
        splitVolume: false,
        organisation: false
    });
    const { currencyFormat, intFormat, numberFormat } = useIntl();

    const [total, setTotal] = useState();

    useEffect(() => {
        let newTotal = 0;
        for (let i = 0; i < rows.length; i++) {
            const row = rows[i];

            newTotal += parseFloat(row.value);
        }

        setTotal(currencyFormat.format(newTotal));
    }, [rows]);

    useEffect(() => {
        setColumnVisibilityModel((prevState) => {
            if (groupBy === 'PRODUCT') {
                return { ...prevState, splitValue: true, splitVolume: true };
            }

            return { ...prevState, splitValue: false, splitVolume: false };
        });
    }, [groupBy]);

    const columns = useMemo(
        () => [
            {
                field: 'group',
                headerName: t('group', { ns: 'orders' }),
                type: 'string',
                flex: 1,
                minWidth: 100,
                renderCell: (params) => {
                    switch (groupBy) {
                        case 'PAYMENT_PROFILE_ID':
                            return getPaymentProfile(params.row?.group, null, t);
                        case 'STATUS':
                            return <Chip label={params.row?.group} color={getStatusColor(params.row?.group)} />;
                        case 'SOURCE':
                            return t(params.row?.group, { ns: 'orders' });
                        default:
                            return params.row?.group;
                    }
                },
                valueGetter: (value) => getPaymentProfileName(value)
            },
            groupBy === 'PAYMENT_PROFILE_ID'
                ? {
                      field: 'paymentProfileName',
                      type: 'string',
                      headerName: t('paymentProfile', { ns: 'orders' }),
                      valueGetter: (value, row) => t(getPaymentProfileName(row?.group), { ns: 'orders' }),
                      flex: 1,
                      minWidth: 100
                  }
                : {
                      field: 'ignore',
                      hide: true
                  },
            {
                field: 'cashVolume',
                headerName: t('cashVolume', { ns: 'orders' }),
                type: 'number',
                renderCell: (params) => `${intFormat.format(params.value)}×`,
                valueFormatter: (value) => intFormat.format(value),
                valueGetter: (value) => value && parseInt(value),
                flex: 0.5,
                minWidth: 75
            },
            {
                field: 'cashValue',
                headerName: t('cashTotal', { ns: 'orders' }),
                type: 'number',
                renderCell: (params) => currencyFormat.format(params.value),
                valueFormatter: (value) => numberFormat.format(value),
                valueGetter: (value) => value && parseFloat(value),
                flex: 0.5,
                minWidth: 75
            },
            {
                field: 'pinVolume',
                headerName: t('pinVolume', { ns: 'orders' }),
                type: 'number',
                renderCell: (params) => `${intFormat.format(params.value)}×`,
                valueFormatter: (value) => intFormat.format(value),
                valueGetter: (value) => value && parseInt(value),
                flex: 0.5,
                minWidth: 75
            },
            {
                field: 'pinValue',
                headerName: t('pinTotal', { ns: 'orders' }),
                type: 'number',
                renderCell: (params) => currencyFormat.format(params.value),
                valueFormatter: (value) => numberFormat.format(value),
                valueGetter: (value) => value && parseFloat(value),
                flex: 0.5,
                minWidth: 75
            },
            {
                field: 'balanceCardVolume',
                headerName: t('balanceCardVolume', { ns: 'orders' }),
                type: 'number',
                renderCell: (params) => `${intFormat.format(params.value)}×`,
                valueFormatter: (value) => intFormat.format(value),
                valueGetter: (value) => value && parseInt(value),
                flex: 0.5,
                minWidth: 75
            },
            {
                field: 'balanceCardValue',
                headerName: t('balanceCardTotal', { ns: 'orders' }),
                type: 'number',
                renderCell: (params) => currencyFormat.format(params.value),
                valueFormatter: (value) => numberFormat.format(value),
                valueGetter: (value) => value && parseFloat(value),
                flex: 0.5,
                minWidth: 75
            },
            {
                field: 'tokenVolume',
                headerName: t('tokenVolume', { ns: 'orders' }),
                type: 'number',
                renderCell: (params) => `${intFormat.format(params.value)}×`,
                valueFormatter: (value) => intFormat.format(value),
                valueGetter: (value) => value && parseInt(value),
                flex: 0.5,
                minWidth: 75
            },
            {
                field: 'tokenValue',
                headerName: t('tokenValue', { ns: 'orders' }),
                type: 'number',
                renderCell: (params) => currencyFormat.format(params.value),
                valueFormatter: (value) => numberFormat.format(value),
                valueGetter: (value) => value && parseFloat(value),
                flex: 0.5,
                minWidth: 75
            },
            {
                field: 'splitVolume',
                headerName: t('splitVolume', { ns: 'orders' }),
                type: 'number',
                renderCell: (params) => `${intFormat.format(params.value)}×`,
                valueFormatter: (value) => intFormat.format(value),
                valueGetter: (value) => value && parseInt(value),
                flex: 0.5,
                minWidth: 75
            },
            {
                field: 'splitValue',
                headerName: t('splitValue', { ns: 'orders' }),
                type: 'number',
                renderCell: (params) => currencyFormat.format(params.value),
                valueFormatter: (value) => numberFormat.format(value),
                valueGetter: (value) => value && parseFloat(value),
                flex: 0.5,
                minWidth: 75
            },
            {
                field: 'volume',
                headerName: t('totalVolume', { ns: 'orders' }),
                type: 'number',
                renderCell: (params) => `${intFormat.format(params.value)}×`,
                valueFormatter: (value) => intFormat.format(value),
                valueGetter: (value) => value && parseInt(value),
                flex: 0.5,
                minWidth: 75
            },
            {
                field: 'value',
                headerName: t('total', { ns: 'orders' }),
                type: 'number',
                renderCell: (params) => currencyFormat.format(params.value),
                valueFormatter: (value) => numberFormat.format(value),
                valueGetter: (value) => value && parseFloat(value),
                flex: 0.5,
                minWidth: 75
            },
            {
                field: 'organisation',
                headerName: t('organisation', { ns: 'translation' }),
                type: 'string',
                flex: 1,
                minWidth: 150
            }
        ],
        [t, currencyFormat, groupBy]
    );

    return (
        <FullHeightDataGrid
            {...props}
            columns={columns}
            rows={rows}
            getRowId={(row) => row.group}
            pagination
            components={{
                Footer: FooterTotals
            }}
            componentsProps={{
                footer: { total }
            }}
            initialState={{
                aggregation: {
                    model: {
                        cashVolume: 'sum',
                        cashValue: 'sum',
                        pinVolume: 'sum',
                        pinValue: 'sum',
                        balanceCardVolume: 'sum',
                        balanceCardValue: 'sum',
                        tokenVolume: 'sum',
                        tokenValue: 'sum',
                        splitVolume: 'sum',
                        splitValue: 'sum',
                        value: 'sum',
                        volume: 'sum'
                    }
                }
            }}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
            loading={loading}
            total={total}
            experimentalFeatures={{ aggregation: true }}
        />
    );
}

TransactionLogGroupedTable.propTypes = {
    rows: PropTypes.array,
    loading: PropTypes.bool,
    groupBy: PropTypes.string
};

export default TransactionLogGroupedTable;
