const map = {
    1: {
        name: 'cash',
        url: '/assets/payment_method_icons/token.png'
    },
    2: {
        name: 'balanceCard',
        url: '/assets/payment_method_icons/balancecard.png'
    },
    3: {
        name: 'cashTopup',
        url: '/assets/payment_method_icons/balancecard.png'
    },
    4: {
        name: 'offline'
    },
    5: {
        name: 'balancecardRefund',
        url: '/assets/payment_method_icons/balancecard.png'
    },
    6: {
        name: 'vivaWallet',
        url: '/assets/payment_method_icons/viva.png'
    },
    7: {
        name: 'token',
        url: '/assets/payment_method_icons/token.png'
    },
    8: {
        name: 'split'
    },
    // Maestro pin
    1630: {
        brandId: 6,
        name: 'maestroDebit'
    },
    // Mastercard pin
    1636: {
        brandId: 8,
        name: 'mastercardCredit'
    },
    // Online payment, pending method selection
    1663: {
        brandId: 167,
        name: 'online'
    },
    // Visa pin
    2369: {
        brandId: 34,
        name: 'visaCredit'
    },
    // Pin pending
    1927: {
        brandId: 164,
        name: 'pin'
    },
    // Pin eigen contract
    1723: {
        brandId: 164,
        name: 'pinEigenContract'
    },
    // AmEx pin
    2759: {
        brandId: 9,
        name: 'amexCredit'
    },
    // Visa debit NL
    3051: {
        brandId: 34,
        name: 'visaDebit'
    },
    // Just AmEx, idk what the difference is
    1705: {
        brandId: 9,
        name: 'amexCredit'
    },
    // V-Pay pin
    1633: {
        brandId: 32,
        name: 'vpayDebit'
    },
    // iDeal
    10: {
        brandId: 1,
        name: 'ideal'
    },
    // Mastercard debit NL
    3048: {
        brandId: 8,
        name: 'mastercardDebit'
    },
    436: {
        brandId: 2,
        name: 'bancontact'
    },
    706: {
        brandId: 2277,
        name: 'applePay'
    },
    712: {
        brandId: 114,
        name: 'applePay'
    },
    2277: {
        brandId: 114,
        name: 'applePay'
    },
    2351: {
        name: 'pinRefund',
        url: '/assets/payment_method_icons/pinRefund.png'
    },
    3069: {
        brandId: 261,
        name: 'softpos'
    },
    9: {
        brandId: 261,
        name: 'softpos'
    },
    balanceCard: {
        name: 'balance_card',
        url: '/assets/payment_method_icons/balancecard.png'
    },
    cash: {
        name: 'cash'
    },
    pin: {
        name: 'pin'
    },
    softpos: {
        name: 'softpos'
    },
    mobile_app: {
        name: 'notAvailable'
    }
};

export function getPaymentProfileIcon(paymentProfileId) {
    const mapValue = map[paymentProfileId];

    if (mapValue === undefined) {
        return null;
    }

    if (Object.hasOwn(mapValue, 'brandId')) {
        return `https://static.pay.nl/payment_profile_brands/100x100/${mapValue.brandId}.svg`;
    } else if (Object.hasOwn(mapValue, 'url')) {
        return mapValue.url;
    } else {
        return '/assets/payment_method_icons/placeholder.png';
    }
}

export function getPaymentProfileName(paymentProfileId) {
    const mapValue = map[paymentProfileId];

    if (mapValue !== undefined && Object.hasOwn(mapValue, 'name')) {
        return mapValue.name;
    }

    return paymentProfileId;
}
