import { Box } from '@mui/material';
import { GridPagination } from '@mui/x-data-grid-premium';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const FooterTotals = ({ total }) => {
    const { t } = useTranslation(['translation', 'statistics']);

    return (
        <>
            <Grid2 container>
                {!_.isNil(total) && (
                    <Grid2 xs={2}>
                        <Box sx={{ p: 1, display: 'flex' }}>
                            {t('total', { ns: 'translation' })}: {total}
                        </Box>
                    </Grid2>
                )}
                <Grid2 xs={!_.isNil(total) ? 10 : 12}>
                    <GridPagination />
                </Grid2>
            </Grid2>
        </>
    );
};

FooterTotals.propTypes = {
    total: PropTypes.string
};

export default FooterTotals;
