import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as sessionApi from '../api/session';
import * as userApi from '../api/user';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import User from '../api/endpoints/user';
import _ from 'lodash';
import fetcher from '../fetcher';

const AuthContext = createContext({});

export function AuthProvider({ children }) {
    const [user, setUser] = useState({});
    const [isAdmin, setIsAdmin] = useState(false);
    const [settings, setSettings] = useState([]);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [loadingInitial, setLoadingInitial] = useState(true);
    const [returnUrl, setReturnUrl] = useState('/dashboard');
    const navigate = useNavigate();
    const location = useLocation();

    /*
        Reset errors on page change.
        We only want this to re-run on path changes, not when the error state changes, so we can ignore ESLint here.
    */
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (error) {
            // TODO: Fix toast error not popping up
            toast.error(error);
            setError(null);
        }
    }, [error]);

    useEffect(() => {
        setReturnUrl(location.href);
    }, [location]);

    // Check if there is a currently active session.
    useEffect(() => {
        const activeUser = userApi.getCurrentUser();

        if (activeUser !== null) {
            try {
                User.getUserSettings().then((result) => {
                    if (result) {
                        setUser(activeUser);
                        setSettings(result.result);
                    }
                });
            } catch (e) {
                setUser({});
                setSettings([]);
            }
        }

        setLoadingInitial(false);
    }, []);

    useEffect(() => {
        if (user !== null && user !== undefined && Object.keys(user).length > 0) {
            navigate(returnUrl, { replace: true });

            if (!_.isNil(_.find(user.roles, (role) => role.name === 'admin'))) {
                setIsAdmin(true);
            } else {
                setIsAdmin(false);
            }
        }
    }, [user]);

    async function verifySecondFactor(code, token, remember) {
        return fetcher(`v1/auth/login/two-factor?code=${code}&token=${token}&remember=${remember}`, true).then(async (response) => {
            if (response) {
                if (!response.ok) {
                    return false;
                }

                response = await response.json();

                const user = response.user;

                localStorage.setItem('user', JSON.stringify(user));

                localStorage.setItem(
                    'authToken',
                    JSON.stringify({
                        token: response.authToken,
                        expires: response.authTokenExpires
                    })
                );

                localStorage.setItem(
                    'refreshToken',
                    JSON.stringify({
                        token: response.refreshToken,
                        expires: response.refreshTokenExpires
                    })
                );

                User.getUserSettings().then((result) => {
                    if (result) {
                        setUser(user);
                        setSettings(result.result);
                    }
                });

                return true;
            }
        });
    }

    async function login(email, password, remember) {
        setLoading(true);

        return sessionApi
            .login({ email, password, remember })
            .then((newUser) => {
                // TOTP required
                if (typeof newUser === 'string') {
                    return newUser;
                }

                User.getUserSettings().then((result) => {
                    if (result) {
                        setUser(newUser);
                        setSettings(result.result);
                    }
                });

                return true;
            })
            .catch((error) => {
                console.error(error);
                setError(error);
                return error;
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function logout() {
        setLoading(true);

        sessionApi
            .logout()
            .then(() => {
                setUser({});
                setSettings([]);
                navigate('/login', { replace: true });
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const getSetting = useCallback(
        (key) => {
            if (settings.length <= 0) {
                User.getUserSettings().then((result) => {
                    if (result) {
                        setSettings(result.result);

                        return result.result.find((setting) => setting.setting.key === key.toLowerCase());
                    }
                });
            } else {
                return settings.find((setting) => setting.setting.key === key.toLowerCase());
            }
        },
        [settings]
    );

    /**
     * @param {string|null} role
     *
     * @returns boolean|object
     */
    async function check(role = null) {
        setLoading(true);

        return await sessionApi
            .check(role)
            .then((result) => {
                if (Object.keys(result).length > 0) {
                    setUser(result);
                    return true;
                }

                return false;
            })
            .catch((error) => {
                setError(error);
                return false;
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const getUser = useCallback(() => {
        if (user === null) {
            sessionApi.destroySession();

            navigate('/login', { replace: true });
            return {};
        }

        return user;
    }, [user]);

    function _setReturnUrl(url) {
        setReturnUrl(url);
    }

    // Only re-render when deps change, not every time a user does anything at all
    const memoedValue = useMemo(
        () => ({
            getUser,
            isAdmin,
            loading,
            error,
            _setReturnUrl,
            login,
            logout,
            check,
            getSetting,
            verifySecondFactor
        }),
        [loading, error, getUser, _setReturnUrl, login, logout, check, getSetting, isAdmin, verifySecondFactor]
    );

    return <AuthContext.Provider value={memoedValue}>{!loadingInitial && children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
    children: PropTypes.object
};

export default function useAuth() {
    return useContext(AuthContext);
}
