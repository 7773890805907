import { GridToolbar as MuiGridToolbar, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid-premium';
import { Button, Grid } from '@mui/material';
import PropTypes from 'prop-types';

function GridToolbar(props) {
    const { customToolbarButtons } = props;

    return (
        <GridToolbarContainer>
            <MuiGridToolbar>
                <GridToolbarExport
                    csvOptions={{
                        utf8WithBom: true
                    }}
                />
            </MuiGridToolbar>
            {!_.isNil(customToolbarButtons) &&
                customToolbarButtons.map((button) => (
                    <Grid key={button.title} item xs={2}>
                        {button.show && <Button {...button.props}>{button.title}</Button>}
                    </Grid>
                ))}
            <GridToolbarQuickFilter />
        </GridToolbarContainer>
    );
}

GridToolbar.propTypes = {
    lastUpdated: PropTypes.string,
    printAction: PropTypes.func,
    showPrintButton: PropTypes.bool,
    customToolbarButtons: PropTypes.array
};

export default GridToolbar;
