import { useMemo } from 'react';
import { getPaymentProfile, getStatusColor } from '../misc/utilities';
import { Chip, Tooltip } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import useIntl from '../../hooks/useIntl';
import PropTypes from 'prop-types';
import { getPaymentProfileName } from '../misc/PaymentProfileMap';
import FullHeightDataGrid from './FullHeightDataGrid';
import { GridActionsCellItem } from '@mui/x-data-grid-premium';
import { ContentCopy } from '@mui/icons-material';
import { toast } from 'react-toastify';
import useAuth from '../../hooks/useAuth';

function TransactionLogTable(props) {
    const { handleTransactionClick, rows, loading, adminMode = false, includePending = true } = props;

    const { t } = useTranslation(['translation', 'orders']);
    const { currencyFormat, conjunctionListFormat, intFormat, numberFormat } = useIntl();
    const { isAdmin } = useAuth();

    const columns = useMemo(
        () => [
            {
                field: 'transactionId',
                headerName: t('transactionId', { ns: 'orders' }),
                type: 'string',
                flex: 1,
                minWidth: 150
            },
            {
                field: 'paymentProfileId',
                headerName: '',
                type: 'string',
                renderCell: (params) => getPaymentProfile(params.row?.paymentProfileId, params.row?.type, t),
                valueGetter: (value, row) => t(getPaymentProfileName(value ?? row?.type.split('@')[0]), { ns: 'orders' }),
                width: 50
            },
            {
                field: 'paymentProfileName',
                headerName: t('paymentProfile', { ns: 'orders' }),
                valueGetter: (value, row) => t(getPaymentProfileName(row?.paymentProfileId ?? row?.type.split('@')[0]), { ns: 'orders' }),
                flex: 1,
                minWidth: 100
            },
            {
                field: 'status',
                headerName: t('status', { ns: 'orders' }),
                type: 'string',
                renderCell: (params) => (
                    <Tooltip title={params.value}>
                        <Chip label={params.value} color={getStatusColor(params.row?.status)} />
                    </Tooltip>
                ),
                valueGetter: (value) => t(value, { ns: 'orders' }),
                flex: 1,
                minWidth: 100
            },
            {
                field: 'products',
                headerName: t('products', { ns: 'orders' }),
                type: 'string',
                valueGetter: (value) => conjunctionListFormat.format(Object.keys(value)),
                flex: 1,
                minWidth: 100
            },
            {
                field: 'productsAmount',
                headerName: t('productsAmount', { ns: 'orders' }),
                type: 'number',
                valueFormatter: (value) => `${intFormat.format(value)}×`,
                valueGetter: (value) => value && parseFloat(value),
                flex: 0.5,
                minWidth: 75
            },
            {
                field: 'type',
                headerName: t('type', { ns: 'orders' }),
                type: 'string',
                flex: 1,
                valueGetter: (value) => t(value.split('@')[0], { ns: 'orders' }),
                minWidth: 100
            },
            {
                field: 'source',
                headerName: t('source', { ns: 'orders' }),
                type: 'string',
                flex: 1,
                valueGetter: (value, row) => {
                    const source = row?.type.split('@')[1];

                    if (source === 'mobile_app' || source === 'mobile_sync') {
                        return `${t(source, { ns: 'orders' })} (v${row?.appVersion}+${row?.buildNumber})`;
                    } else {
                        return t(source, { ns: 'orders' });
                    }
                },
                minWidth: 100
            },
            {
                field: 'date',
                headerName: t('date', { ns: 'translation' }),
                type: 'dateTime',
                flex: 1,
                minWidth: 150,
                valueFormatter: (value) => (value !== '' ? DateTime.fromJSDate(value).toLocaleString(DateTime.DATETIME_SHORT) : ''),
                valueGetter: (value) => DateTime.fromISO(value).toJSDate()
            },
            {
                field: 'duration',
                headerName: t('duration', { ns: 'orders' }),
                flex: 1,
                minWidth: 150,
                type: 'string',
                valueGetter: (value, row) =>
                    row?.stopTime !== null && row?.startTime !== null ? `${row?.stopTime - row?.startTime}s` : null
            },
            {
                field: 'total',
                headerName: t('total', { ns: 'orders' }),
                type: 'number',
                renderCell: (params) => currencyFormat.format(params.value),
                valueFormatter: (value) => numberFormat.format(value),
                valueGetter: (value) => value && parseFloat(value),
                flex: 0.5,
                minWidth: 75
            },
            {
                field: 'terminal',
                headerName: t('terminal', { ns: 'orders' }),
                type: 'string',
                flex: 1,
                minWidth: 100,
                valueGetter: (value) => (value !== undefined ? `${value.name.substring(4)} (${value.thNumber})` : '')
            },
            {
                field: 'cashRegister',
                headerName: t('cashRegister', { ns: 'orders' }),
                type: 'string',
                flex: 1,
                minWidth: 200,
                valueGetter: (value) => value?.name
            },
            adminMode
                ? {
                      field: 'event',
                      headerName: t('event', { ns: 'translation' }),
                      type: 'string',
                      flex: 1,
                      minWidth: 200,
                      valueGetter: (value, row) => `${value?.name} (${row?.organisation?.name})`
                  }
                : {
                      field: 'a'
                  },
            {
                field: 'actions',
                type: 'actions',
                flex: 0.1,
                getActions: (params) => [
                    ...(isAdmin
                        ? [
                              <GridActionsCellItem
                                  key="copy_id"
                                  label={t('copyId', { ns: 'translation' })}
                                  icon={<ContentCopy />}
                                  onClick={() => {
                                      navigator.clipboard.writeText(params.row?.id).then(() => {
                                          toast.success(t('idCopied', { ns: 'translation' }), {
                                              autoClose: 1000
                                          });
                                      });
                                  }}
                                  showInMenu
                              />
                          ]
                        : [])
                ]
            }
        ],
        [t, currencyFormat, conjunctionListFormat, isAdmin]
    );

    return (
        <FullHeightDataGrid
            {...props}
            initialState={{
                sorting: {
                    sortModel: [{ field: 'date', sort: 'desc' }]
                },
                filter: {
                    filterModel: {
                        items: [
                            {
                                field: 'status',
                                operator: 'isAnyOf',
                                value: [t('complete', { ns: 'orders' }), ...(includePending ? [t('pending', { ns: 'orders' })] : [])]
                            }
                        ]
                    }
                },
                columns: {
                    columnVisibilityModel: {
                        transactionId: false,
                        products: false,
                        productsAmount: false,
                        a: false
                    }
                }
            }}
            onRowClick={handleTransactionClick}
            columns={columns}
            rows={rows}
            pagination
            loading={loading}
        />
    );
}

TransactionLogTable.propTypes = {
    handleTransactionClick: PropTypes.func.isRequired,
    rows: PropTypes.array,
    loading: PropTypes.bool,
    adminMode: PropTypes.bool,
    includePending: PropTypes.bool
};

export default TransactionLogTable;
