const colours = {
    cpoc: '#00ff00',
    pin: '#ff0000',
    token: '#0000ff',
    pinRefund: '#ff00ff',
    offline_cpoc: '#ffff00',
    balanceCard: '#00ffff'
};

function stringToColour(string) {
    if (colours[string] !== undefined) {
        return colours[string];
    }

    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export default stringToColour;
