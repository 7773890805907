import { toast } from 'react-toastify';
import _ from 'lodash';

/**
 * @param {string} url
 * @param {object} givenOptions
 *
 * @returns {Request}
 */
const buildRequest = (url, givenOptions = {}) => {
    let authToken = localStorage.getItem('authToken');
    let headers;

    if (authToken !== null) {
        authToken = JSON.parse(authToken);

        headers = new Headers({
            Authorization: `Bearer ${window.btoa(authToken.token)}`,
            'Content-Type': 'application/json',
            ...givenOptions.headers
        });
    } else {
        headers = new Headers({
            'Content-Type': 'application/json',
            ...givenOptions.headers
        });
    }

    const options = {
        ...givenOptions,
        headers: headers
    };

    return new Request(url, { ...options });
};

/**
 * Fetches a resource and automatically adds authorization tokens to the headers.
 *
 * @param {string} url
 * @param {object?} options
 * @param {boolean?} forceRefresh
 * @param {string} thing
 * @param showToast
 *
 * @returns {Promise<Response>|boolean}
 */
async function fetcher(url, forceRefresh = false, options, thing = 'Object', showToast = true) {
    let result;

    if (url.startsWith('/') || process.env.REACT_APP_API_URL.endsWith('/')) {
        url = `${process.env.REACT_APP_API_URL}${url}`;
    } else {
        url = `${process.env.REACT_APP_API_URL}/${url}`;
    }

    if ((options !== undefined && options.method !== 'GET' && options.method !== 'HEAD') || true) {
        console.debug('Not caching request');
        result = await fetch(buildRequest(url, options));
    } else {
        result = await getData(buildRequest(url, options), forceRefresh);
    }

    if (!result) {
        return false;
    }

    const clone = result.clone();

    if (!result.ok) {
        const resultText = await result.text();

        if (resultText !== null && resultText !== '' && showToast) {
            toast.error(resultText);
        }
    } else if (result.status === 201 && showToast) {
        toast.success(`${thing} created`);
    } else if (options !== undefined && options.method === 'PATCH' && showToast) {
        toast.success(`${thing} updated`);
    }

    return clone;
}

export async function fetcherV2(version, namespace, endpoint, { forceRefresh = false, options, thing = 'Object', showToast = true, user }) {
    let id;

    switch (namespace) {
        case 'organisation':
            id = user.organisation?.id;

            break;
        case 'admin':
        case 'user':
        default:
            id = user.id;

            break;
    }

    if (_.isNil(id)) return;

    const path = `/v${version}/${namespace}/${id}/${endpoint}`;

    return fetcher(path, forceRefresh, options, thing, showToast);
}

/**
 * @param {string} cacheName
 * @param {Request} url
 * @returns {Promise<Response>|boolean}
 */
const getCachedData = async (cacheName, url) => {
    const cacheStorage = await caches.open(cacheName);
    const cachedResponse = await cacheStorage.match(url);

    if (!cachedResponse || !cachedResponse.ok) {
        return false;
    }

    const responseDate = new Date(cachedResponse.headers.get('date')).getTime();
    const currentDate = new Date().getTime();

    // 5 minutes in milliseconds
    if (currentDate - responseDate >= 5 * 60 * 1000) {
        return false;
    }

    return cachedResponse;
};

/**
 * @param {Request} url
 * @param {boolean} forceRefresh
 *
 * @returns {Promise<Response> | Boolean}
 */
const getData = async (url, forceRefresh) => {
    const cacheName = 'api';

    if (!forceRefresh) {
        const cachedData = await getCachedData(cacheName, url);

        if (cachedData) {
            return cachedData;
        }
    }

    const cacheStorage = await caches.open(cacheName);

    try {
        return fetch(url).then(async (response) => {
            if (!response.ok) {
                return false;
            }

            await cacheStorage.put(url, response);

            return getCachedData(cacheName, url);
        });
    } catch (e) {
        return false;
    }
};

export default fetcher;
