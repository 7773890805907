import i18next from 'i18next';

export async function validateResolution(value, rangeInMinutes) {
    await i18next.loadNamespaces('validation');

    if (value !== '') {
        const parsedValue = parseInt(value);
        const dataPoints = rangeInMinutes / parsedValue;

        if (dataPoints > process.env.REACT_APP_MAX_DATA_POINTS) {
            return {
                error: true,
                helperText: i18next.t('errors.tooManyDataPoints', {
                    minValue: Math.ceil(rangeInMinutes / process.env.REACT_APP_MAX_DATA_POINTS),
                    ns: 'validation'
                }),
                value: parsedValue
            };
        }

        if (!isNaN(parsedValue)) {
            return {
                error: false,
                helperText: ' ',
                value: parsedValue
            };
        } else {
            return {
                error: true,
                helperText: i18next.t('errors.numeric', { ns: 'validation' }),
                value: parsedValue
            };
        }
    }
}
