import OrderStatistics from '../../panels/OrderStatistics';
import DateRangeOptions from '../../misc/DateRangeOptions';
import Grid2 from '@mui/material/Unstable_Grid2';
import StatusPie from '../../panels/StatusPie';
import SourcePie from '../../panels/SourcePie';
import PaymentMethodPie from '../../panels/PaymentMethodPie';
import { useEffect, useState } from 'react';
import useDateRange from '../../../hooks/useDateRange';
import { validateResolution } from '../../../validation/statistics';
import TransactionLog from '../../TransactionLog';
import { Box } from '@mui/material';
import OrdersPerSecond from '../orders-per-second';

function FullScreen() {
    const dateRange = useDateRange();

    const [statsResolution, setStatsResolution] = useState(5);
    const [validationErrors, setValidationErrors] = useState({
        resolution: {
            error: false,
            helperText: ' '
        }
    });

    useEffect(() => {
        let minResolution;
        const newDateRange = dateRange.getDateRange();

        minResolution = Math.ceil(newDateRange.end.diff(newDateRange.start, 'minutes').minutes / process.env.REACT_APP_MAX_DATA_POINTS);

        handleResolutionBlur({ target: { value: minResolution } });
    }, [dateRange.dateRange]);

    const handleResolutionBlur = async (event) => {
        const newDateRange = dateRange.getDateRange();

        const minutesInRange = newDateRange.end.diff(newDateRange.start, 'minutes').minutes;

        const validation = await validateResolution(event.target.value, minutesInRange);

        if (!validation.error) {
            setStatsResolution(validation.value);
        }

        setValidationErrors({
            ...validationErrors,
            resolution: validation
        });
    };

    return (
        <>
            <Grid2 container spacing={2} sx={{ m: 1 }}>
                <Grid2 xs={4}>
                    <OrdersPerSecond type="orders" />
                </Grid2>
                <Grid2 xs={4}>
                    <OrdersPerSecond type="turnover" />
                </Grid2>
                <Grid2 xs={12} lg={10}>
                    <div>
                        <Grid2 container spacing={2}>
                            <Grid2 xs={12}>
                                <OrderStatistics adminMode statsResolution={statsResolution} />
                            </Grid2>
                            <Grid2 xs={12}>
                                <Box sx={{ height: '3000px' }}>
                                    <TransactionLog adminMode={true} />
                                </Box>
                            </Grid2>
                        </Grid2>
                    </div>
                </Grid2>
                <Grid2 xs={1}>
                    <DateRangeOptions />
                </Grid2>
            </Grid2>
        </>
    );
}

export default FullScreen;
