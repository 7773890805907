import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useTitle from '../hooks/useTitle';
import DateRangeOptions from './misc/DateRangeOptions';
import PropTypes from 'prop-types';
import Grid2 from '@mui/material/Unstable_Grid2';
import TransactionLogGrid from './panels/TransactionLogGrid';

function TransactionLog({ adminMode = false }) {
    const { t } = useTranslation(['translation', 'navbar', 'orders', 'terminals', 'statistics']);
    const { changeTitle } = useTitle();

    useEffect(() => {
        changeTitle(t('transactionLog', { ns: 'navbar' }));
    }, [changeTitle, t]);

    return (
        <>
            <Grid2 container spacing={2} sx={{ height: '100%' }}>
                <Grid2 xs={adminMode ? 12 : 11} lg={adminMode ? 12 : 10}>
                    <TransactionLogGrid adminMode={adminMode} />
                </Grid2>
                {!adminMode && (
                    <Grid2 xs={1} lg={2}>
                        <DateRangeOptions />
                    </Grid2>
                )}
            </Grid2>
        </>
    );
}

TransactionLog.propTypes = {
    adminMode: PropTypes.bool
};

export default TransactionLog;
