import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

const DateRangeContext = createContext({});

export function DateRangeProvider({ children }) {
    const [selectedValue, setSelectedValue] = useState('8h');
    const [selectedGroupByValue, setSelectedGroupByValue] = useState('default');
    const [customDateRange, setCustomDateRange] = useState([DateTime.now().startOf('week'), DateTime.now().endOf('week')]);
    const [expanded, setExpanded] = useState(false);
    const [dateRange, setDateRange] = useState({ start: DateTime.now().minus({ hours: 8 }), end: DateTime.now() });
    const [refreshDelay, setRefreshDelay] = useState(0);
    const [refreshInterval, setRefreshInterval] = useState(null);
    const [groupBy, setGroupBy] = useState(null);
    const [availableGroups, setAvailableGroups] = useState([]);

    const getDateRange = useCallback(() => {
        switch (selectedValue) {
            case '8h':
                return { start: DateTime.now().minus({ hours: 8 }), end: DateTime.now() };
            case '24h':
                return { start: DateTime.now().minus({ hours: 24 }), end: DateTime.now() };
            case 'today':
                return { start: DateTime.now().startOf('day'), end: DateTime.now().endOf('day') };
            case 'yesterday':
                return {
                    start: DateTime.now().minus({ days: 1 }).startOf('day'),
                    end: DateTime.now().minus({ days: 1 }).endOf('day')
                };
            case 'thisWeek':
                return { start: DateTime.now().startOf('week'), end: DateTime.now().endOf('week') };
            case 'lastWeek':
                return {
                    start: DateTime.now().minus({ weeks: 1 }).startOf('week'),
                    end: DateTime.now().minus({ weeks: 1 }).endOf('week')
                };
            case 'other':
            default:
                return { start: customDateRange[0], end: customDateRange[1] };
        }
    }, [selectedValue, customDateRange]);

    const memoedValue = useMemo(
        () => ({
            selectedValue,
            setSelectedValue,
            selectedGroupByValue,
            setSelectedGroupByValue,
            customDateRange,
            setCustomDateRange,
            expanded,
            setExpanded,
            dateRange,
            setDateRange,
            refreshDelay,
            setRefreshDelay,
            refreshInterval,
            setRefreshInterval,
            groupBy,
            setGroupBy,
            availableGroups,
            setAvailableGroups,
            getDateRange
        }),
        [
            selectedValue,
            selectedGroupByValue,
            customDateRange,
            expanded,
            dateRange,
            refreshDelay,
            refreshInterval,
            groupBy,
            availableGroups,
            getDateRange
        ]
    );

    return <DateRangeContext.Provider value={memoedValue}>{children}</DateRangeContext.Provider>;
}

DateRangeProvider.propTypes = {
    children: PropTypes.object
};

export default function useDateRange() {
    return useContext(DateRangeContext);
}
