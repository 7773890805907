import { useEffect, useRef, useState } from 'react';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import fetcher from '../../fetcher';
import useIntl from '../../hooks/useIntl';
import PropTypes from 'prop-types';

const OrdersPerSecond = ({ duration = 10, type = 'orders' }) => {
    const { numberFormat, currencyFormat } = useIntl();

    const [transactionsPerSecond, setTransactionsPerSecond] = useState(null);

    const interval = useRef(null);

    const getOrdersPerSecond = () => {
        fetcher(`v1/order/statistic/per-second?duration=${duration}`).then(async (result) => {
            if (result) {
                const body = await result.text();

                setTransactionsPerSecond(parseFloat(body));
            }
        });
    };

    useEffect(() => {
        if (interval.current != null) {
            clearInterval(interval.current);
        }

        getOrdersPerSecond();

        interval.current = setInterval(() => {
            getOrdersPerSecond();
        }, 15 * 1000);
    }, []);

    useEffect(
        () => () => {
            clearInterval(interval.current);
            interval.current = null;
        },
        []
    );

    return (
        <Card>
            <CardHeader subheader={`Avg. past ${duration} min.`} />
            <CardContent>
                <Typography variant="h2">
                    {type === 'orders'
                        ? `${numberFormat.format(transactionsPerSecond)}/s`
                        : `${currencyFormat.format(transactionsPerSecond)}`}
                </Typography>
            </CardContent>
        </Card>
    );
};

OrdersPerSecond.propTypes = {
    duration: PropTypes.number,
    type: PropTypes.string
};

export default OrdersPerSecond;
